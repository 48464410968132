import Header from "../../../../../Home/screens/Header/Header";
import styled from "styled-components";
import RightViewSection from "../RightViewSection/RightViewSection";
import CopyRight from "../../../../../Home/screens/CopyRight/CopyRight";
import { HiArrowLeft } from "react-icons/hi";
import { CAKE } from "./CAKE";
import BookingSummary from "../BookingSummery/BookingSummary";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Tick from "../../../../../../assets/images/Tick.png";
import {
    getActiveVariants,
    getBookingSummary,
    getCouponInfo,
    getIsCouponAppliedSuccessfully,
    getSelectedTheater, getSelectedTheaterDate, getSelectedTheaterTime,
    getSubTotalAmount,
} from "../../../../../../redux/selectors/bookingSelector";
import {
  setBookingSummary,
  setBookingSummaryCake,
  setSubtotalAmount,
  setActiveVariants
} from "../../../../../../redux/actions/bookingActions";
import * as S from "./CakeSelection.style";
import { getUpdateBookingDetail } from "../../../../../../redux/selectors/blogsSelector";
import {store} from "../../../../../../store";
import CutomizationModal from "./CustomizationModal/CustomizationModal";

const ImageContainer = styled.div`
  background-color: #ffffff;
  border-radius: 50%;
  width: 160px;
  height: 160px;
  box-shadow: 0px 10px 35px 0px #575c8a1f;

  @media only screen and (max-width: 786px) {
    width: 130px;
    height: 130px;
  } 
`;
interface FilterTagProps {
  isSelected: boolean;
}
 const FilterTag = styled.div<FilterTagProps>`
  background-color: ${(props) => (props.isSelected ? '#34265C' : '#e0e7ff')};
  border-radius: 15px;
  padding: 5px 10px;`

  const FilterTagText = styled.div<FilterTagProps>`
  color:${(props) => (props.isSelected ? '#ffffff' : '#000000')};
  font-size: 14px;
`
export interface Cake {
  name: string;
  price: number;
  iconUrl: string;
  index: number;
  category: string;
  details: {
    unit: string;
    quantity: number;
    price: number;
  }[];
}
export interface ActiveVariant {
  name: string;
  price: number;
  quantity: number;
  unit: string;
}

const CakeSelection = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const bookingSummary = useSelector(getBookingSummary);
  const selectedTheaterDate = useSelector(getSelectedTheaterDate);
  const selectedTheaterTime = useSelector(getSelectedTheaterTime);
  const activeVariantsSelector = useSelector(getActiveVariants);
  const updateBookingDetail = useSelector(getUpdateBookingDetail) || {};

  const selectedTheater = useSelector(getSelectedTheater);
  const CAKES = selectedTheater?.cake || [];
  const [activeVariants, setActiveVariant] = useState<ActiveVariant[]>(activeVariantsSelector);
  const [selectedTag, setSelectedTag] = useState('Standard');
  const initialCake: Cake = {
    name: "",
    price: 0,
    iconUrl: "",
    index: 0,
    category: "",
    details: [
      {
        unit: "",
        quantity: 0,
        price: 0,
      },
    ],
  };

  const [currentCake, setCurrentCake] = useState<Cake>(initialCake);
  const isCouponApplied = useSelector(getIsCouponAppliedSuccessfully);
  const subTotalAmount = useSelector(getSubTotalAmount) || 0;
  const coupon = useSelector(getCouponInfo);
  const { code: couponCode, price: couponPrice } = coupon || {};

  // const [bookingSummaryData, setBookingSummaryData] =
  //   useState<any>(bookingSummary);

  const [bookingSummaryCakes, setBookingSummaryCakes] = useState<any>(
    bookingSummary.cake || []
  );
  // const [bookingSummaryAddons, setBookingSummaryAddons] = useState<any>(
  //   bookingSummary.addOns || []
  // );
  const { subTotal, advancePayment } = bookingSummary || {};
  const [timeCheck, setTimeCheck] = useState(true);
  const [showBookingSummary, setShowBookingSummary] = useState<Boolean>(false);
  const [hoverCake, setHoverCake] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal

  const openModal = () => {
    setIsModalOpen(true); // Open modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close modal
  };
  useEffect(() => {
    setActiveVariant(activeVariantsSelector);
  }, [activeVariantsSelector]);

  const covert12HrTo24HrFormat = (timeSlot: any) => {
    const [time, modifier] = timeSlot.split(" ");
    let [hours, minutes] = time.split(":");
    if (hours === "12") {
      hours = "00";
    }
    if (modifier === "PM") {
      hours = (parseInt(hours, 10) + 12).toString();
    }
    return [parseInt(hours), parseInt(minutes)];
  };

    useEffect(() => {
        const today = new Date(
            new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
        );
        const [hours, minutes ] = covert12HrTo24HrFormat(selectedTheaterTime.split("-")[0].trim());
        const selDate = new Date(selectedTheaterDate.fullDate);
        selDate.setHours(hours, minutes, 0, 0);
        const timeDifferenceMs = selDate.getTime() - today.getTime();
        const timeDifferenceHours = timeDifferenceMs / (1000 * 60 * 60);
        // Compare the two dates
        console.log("Dates are ",selectedTheaterTime,new Date(selectedTheaterDate.fullDate), today,new Date(selectedTheaterDate.fullDate) < today );
        if (timeDifferenceHours < 12) {
            setTimeCheck(false);
        }
    }, []);

  const handleNext = () => {
    navigate("choose_decoration");
  };

  const handleSelectedCake = (cake: Cake) => {
    // Check if the current cake is already in active variants
    setCurrentCake(cake);
    const variantIndex = (activeVariants ?? []).findIndex(variant => variant.name === cake.name);
  
    if (variantIndex !== -1) {
      // Remove the cake from active variants
      const updatedVariants = activeVariants.filter((_, index) => index !== variantIndex);
      setActiveVariant(updatedVariants);
    } else {
      if (cake.details.length > 1) {
        setIsModalOpen(true);
      } else {
        // Create a new variant object
        const newVariant: ActiveVariant = {
          name: cake.name,
          price: cake.details[0].price,
          quantity: cake.details[0].quantity,
          unit: cake.details[0].unit,
        };
  
        // Update the state to include the new variant
        setActiveVariant((prevVariants : ActiveVariant[])=> [...prevVariants, newVariant]);
      }
    }
  }; 

  useEffect(() => {
    if (!updateBookingDetail.cake || updateBookingDetail.cake.length === 0)
      return;

    const cakeNames = updateBookingDetail?.cake
      ?.split(",")
      ?.map((cake: any) => {
        return cake?.split?.("(")?.[0]?.trim();
      });

    const filteredCakes = [...CAKES]?.filter(
      (cake: { name: String; price: number; iconUrl: string; index: number }) =>
        cakeNames?.includes(cake.name)
    );
    setBookingSummaryCakes([...filteredCakes]);
  }, [updateBookingDetail.cake]);

  useEffect(() => {
    dispatch(setBookingSummaryCake(bookingSummaryCakes));
    store.dispatch(setSubtotalAmount());
  }, [bookingSummaryCakes]);
  useEffect(() => {
    setBookingSummaryCakes(activeVariants);
    dispatch(setActiveVariants(activeVariants));
    store.dispatch(setSubtotalAmount());
  }, [activeVariants]);

  const handleMouseOver = (cake: any) => {
    setHoverCake(cake.name);
  };

  const handleShowBookingSummary = () => {
    setShowBookingSummary(!showBookingSummary);
  };

  return (
    <>
    <title>The Binge Town | Cake Selection</title>
      <Header />
      <S.BookingOverviewContainer>
        <S.LeftViewSectionContainer>
          <S.BackButtonContainer onClick={() => navigate(-1)}>
            <HiArrowLeft size={24} />
            <S.BackButtonTxt>Back</S.BackButtonTxt>
          </S.BackButtonContainer>

          <S.Overview>
            <S.HeadingContainer>
              <S.HeadingTxt>Choose Cake</S.HeadingTxt>
              <S.OptionalContainer>(optional)</S.OptionalContainer>
            </S.HeadingContainer>
            <S.FilterContainer>
              <FilterTag
                isSelected={selectedTag === 'Standard'}
                onClick={() => setSelectedTag('Standard')}
              >
                <FilterTagText
                isSelected={selectedTag === 'Standard'}>Standard Cakes</FilterTagText>
              </FilterTag>
                {timeCheck && <FilterTag
                isSelected={selectedTag === 'Premium'}
                onClick={() => setSelectedTag('Premium')}
              >
                <FilterTagText
                isSelected={selectedTag === 'Premium'}>Premium Cakes</FilterTagText>
              </FilterTag>}
            </S.FilterContainer>
            <S.CakeView>
              {CAKES &&
                CAKES.length > 0 &&
                CAKES
                  .filter((cake: Cake) => cake.category === selectedTag.toLowerCase()) // Filter based on selectedTag
                  .map((cake: Cake) => {
                    let cakeQuantity = (cake.category.toLowerCase() == "premium" && cake?.details[0])
                        ? `(${cake?.details[0].quantity} ${cake?.details[0].unit})` : "";
                    return (
                      <S.OccasionView
                        key={cake.name}
                        onClick={() => handleSelectedCake(cake)}
                        onMouseOver={() => handleMouseOver(cake)}
                      >
                        {activeVariants && activeVariants.length > 0 && activeVariants.some(variant => variant.name === cake.name) ? (
                          <ImageContainer>
                            <S.TickImageContainer src={Tick} alt="tick" />
                            <img
                              src={cake.iconUrl}
                              alt={cake.name}
                              title={cake.name}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                overflow: "hidden",
                                top: "-3.3rem",
                                position: "relative",
                              }}
                            />
                          </ImageContainer>
                        ) : (
                          <ImageContainer>
                            <img
                              src={cake.iconUrl}
                              alt={cake.name}
                              title={cake.name}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                overflow: "hidden",
                              }}
                            />
                          </ImageContainer>
                        )}
                        <S.OccasionTitle>
                          {cake.name}
                        </S.OccasionTitle>
                        <S.OccasionPrice>&#8377; {cake.price} {cakeQuantity}</S.OccasionPrice>
                      </S.OccasionView>
                    );
                  })}
            </S.CakeView>
            <S.OptionalContainer>(Note:- The cake images provided are for representation purposes only; actual cakes may vary.)</S.OptionalContainer>
          </S.Overview>

          <S.MwebFooterContainer>
            <S.PriceBreakupSummaryContainer>
              <S.PriceBreakupHeader>
                <S.PriceBreakupTotal>Total</S.PriceBreakupTotal>
                <S.PriceBreakupPrice>
                  &#8377;
                  {isCouponApplied
                    ? subTotalAmount - couponPrice
                    : subTotalAmount}
                </S.PriceBreakupPrice>
              </S.PriceBreakupHeader>
              <S.PriceBreakup>
                <S.BookingSummaryHeading onClick={handleShowBookingSummary}>
                  <S.BookingSummaryTxt> Booking summary</S.BookingSummaryTxt>
                  {showBookingSummary ? (
                    <MdOutlineKeyboardArrowUp size={24} />
                  ) : (
                    <MdOutlineKeyboardArrowDown size={24} />
                  )}
                </S.BookingSummaryHeading>
              </S.PriceBreakup>
              <S.PriceBreakupDetails>
                {showBookingSummary ? <BookingSummary /> : null}
              </S.PriceBreakupDetails>
            </S.PriceBreakupSummaryContainer>
            <S.BookNowBtn
              onClick={handleNext}
              type="submit"
              // disabled={!isFormValid}
            >
              Next step
            </S.BookNowBtn>
          </S.MwebFooterContainer>
        </S.LeftViewSectionContainer>
        <S.RightViewSectionContainer>
          {/* <RightViewSection></RightViewSection> */}
          <BookingSummary fromPage="cake" />
        </S.RightViewSectionContainer>
      </S.BookingOverviewContainer>
      <CopyRight />
      {isModalOpen &&<CutomizationModal isOpen={isModalOpen} onClose={closeModal} cake ={currentCake}/>} 
    </>
  );
};

export default CakeSelection;
