import Theaters from "../Theaters/Theaters";
import Header from "../../../../../Home/screens/Header/Header";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getSelectedLocation,
  getSelectedTheaterDate,
  getTheaterDetails,
  getSelectedCity,
  getActiveTimeSlotInd,
  getSelectedTheater
} from "../../../../../../redux/selectors/bookingSelector";
import { HiArrowLeft } from "react-icons/hi";
import * as S from "./RightViewSection.style";
import { useEffect, useState, lazy, Suspense } from "react";
import {
  resetActiveVariants,
  resetActiveDecorations,
  resetActiveGifts,
  resetActiveServices,
  resetBookingDetails,
  resetBookingSummaryAddons,
  resetExtraNumberOfPeople,
  resetSelectedOccasion,
  setActiveTimeSlotInd,
  setCouponAppliedSuccessfully,
  setSelectedLocation,
  setSelectedTheaterDate,
  setTheaterTimeSlots,
  setSelectedCity,
  setLocationsCityWise,
  setTheaterInfo
} from "../../../../../../redux/actions/bookingActions";
import { PROD } from "../../../../../../endPointsConfig";
import { useSessionManagement } from "../../../../../../tracking/eventTracker";
import Carousel from "react-material-ui-carousel";
import { seoFooterContent } from "../../../../utils";
import { serviceAvailableAtCitiesSelector,locationCodeMapSelector } from "../../../../../../redux/selectors/webConfigSelector";
import HaveDoubts from "../../../../../Home/screens/HaveDoubts/HaveDoubts";
import { Button, useMediaQuery } from "@mui/material";
import { store } from "../../../../../../store";
import EditLocationModal, { CityData } from "./EditLocationModal/EditLocationModal";
import { Helmet, HelmetProvider } from "react-helmet-async";
import seoStrings from "../../../../../../seoSrings";
import { helmetJsonLdProp } from "react-schemaorg";
import { Place } from "schema-dts";
const OtherTheaters = lazy(() => import("../OtherTheaters/OtherTheaters"));


const RightViewSection = () => {
  const selectedTheater = useSelector(getSelectedTheater);
  const isMweb = useMediaQuery("(max-width:786px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search } = location;
  const theaterDetails = useSelector(getTheaterDetails);
  const selectedLocation = useSelector(getSelectedLocation, shallowEqual) || {};
  const selectedCity = useSelector(getSelectedCity) || "";
  const selectedDate = useSelector(getSelectedTheaterDate, shallowEqual) || {};
  const serviceAvailableAtCities = useSelector(serviceAvailableAtCitiesSelector) || [];
  //const [activeTimeSlotInd, setActiveTimeSlotInd] = useState(Array(50).fill(-1));
  const activeTimeSlotInd = useSelector(getActiveTimeSlotInd);
  const theaterCount = (theaterDetails && theaterDetails.length) || 0;
  const [isIphone, setIsIphone] = useState(false);
  const [slotBooked, setSlotBooked] = useState(false);
  const selectedTheaterDate = useSelector(getSelectedTheaterDate);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const locationCodeMap = useSelector(locationCodeMapSelector) || {}
  const monthNames = [
    "January", "February", "March", "April", "May", "June", 
    "July", "August", "September", "October", "November", "December"
  ];
  let locationN: string = selectedLocation?.locationName;
  useEffect(() => {
    // Check if any element in the 1D array is greater than -1
    const isSlotBooked: boolean = activeTimeSlotInd.some((slot: number) => slot > -1);

    // Set the slotBooked state based on the condition
    setSlotBooked(isSlotBooked);
  }, [activeTimeSlotInd]);
  
  // useEffect(() => {
  //   const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

  //   // Check if the device is an iPhone
  //   if (/iPhone/.test(userAgent) && !(window as any).MSStream) {
  //     setIsIphone(true);
  //   } else {
  //     setIsIphone(false);
  //   }
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (!selectedLocation || (selectedLocation && !selectedLocation.locationName)
      && pathname) {
      serviceAvailableAtCities &&
        serviceAvailableAtCities.length > 0 &&
        serviceAvailableAtCities.map((theaterInACity: any, index: any) => {
          for (let loc of theaterInACity.locations) {
            if (pathname.includes(loc.locationName.toLowerCase().replace(' ', '%20'))) {
              store.dispatch(setSelectedLocation(loc));
              let dateStr = search.split('=')[1];
              let [year, month, day] = dateStr.split('_');
              let date = new Date(Number.parseInt(year), Number.parseInt(month) - 1, Number.parseInt(day));
              store.dispatch(
                setSelectedTheaterDate({
                  year: year,
                  month: month,
                  day: day,
                  fullDate: date,
                })
              );
            }
          }
        });
    }
  }, []);

  useSessionManagement("THEATRE");
  useEffect(() => {
    if (
      Object.keys(selectedDate).length === 0 ||
      Object.keys(selectedLocation).length === 0
    ) {
      return;
    }

    const date = `${selectedDate?.year}${selectedDate?.month}${selectedDate?.day}`;
    const theaterTimeSlots: any = {};

    if (selectedLocation?.code?.length > 0) {
      // Create an array of fetch promises
      const sessionId = localStorage.getItem("tbt_njkwe_sessionId") || "";
      const fetchPromises = selectedLocation.code.map((theaterCode: any) => {
        return fetch(`${PROD}/getSlots?code=${theaterCode}_${date}&getDate=false`,
          {
            method: "GET",
            headers: {
                "sessionId": sessionId  // Fallback value ensures this is always a string
            }
        }
        )
          .then(response => response.json())
          .then(response => {
            theaterTimeSlots[theaterCode] = response.slots;
          });
      });

      // Wait for all fetches to complete
      Promise.all(fetchPromises).then(() => {
        // Once all fetches are done, update the state
        dispatch(setTheaterTimeSlots(theaterTimeSlots));
        setActiveTimeSlotInd(Array(50).fill(-1));
      });
    }
  }, [selectedDate, selectedLocation]);

  function intToEnglish(number: any): string {

    var NS = [
      { value: 100, str: "Hundred" },
      { value: 90, str: "Ninety" },
      { value: 80, str: "Eighty" },
      { value: 70, str: "Seventy" },
      { value: 60, str: "Sixty" },
      { value: 50, str: "Fifty" },
      { value: 40, str: "Forty" },
      { value: 30, str: "Thirty" },
      { value: 20, str: "Twenty" },
      { value: 19, str: "Nineteen" },
      { value: 18, str: "Eighteen" },
      { value: 17, str: "Seventeen" },
      { value: 16, str: "Sixteen" },
      { value: 15, str: "Fifteen" },
      { value: 14, str: "Fourteen" },
      { value: 13, str: "Thirteen" },
      { value: 12, str: "Twelve" },
      { value: 11, str: "Eleven" },
      { value: 10, str: "Ten" },
      { value: 9, str: "Nine" },
      { value: 8, str: "Eight" },
      { value: 7, str: "Seven" },
      { value: 6, str: "Six" },
      { value: 5, str: "Five" },
      { value: 4, str: "Four" },
      { value: 3, str: "Three" },
      { value: 2, str: "Two" },
      { value: 1, str: "One" }
    ];

    var result = '';
    for (var n of NS) {
      if (number >= n.value) {
        if (number <= 99) {
          result += n.str;
          number -= n.value;
          if (number > 0) result += ' ';
        } else {
          var t = Math.floor(number / n.value);
          // console.log(t);
          var d = number % n.value;
          if (d > 0) {
            return intToEnglish(t) + ' ' + n.str + ' ' + intToEnglish(d);
          } else {
            return intToEnglish(t) + ' ' + n.str;
          }

        }
      }
    }
    return result;
  };

  useEffect(() => {
    dispatch(resetBookingSummaryAddons());
    dispatch(resetActiveVariants());
    dispatch(resetActiveDecorations());
    dispatch(resetActiveGifts());
    dispatch(resetActiveServices());
    dispatch(resetExtraNumberOfPeople());
    dispatch(resetBookingDetails());
    dispatch(resetSelectedOccasion());
    dispatch(setCouponAppliedSuccessfully(false));
    dispatch(setActiveTimeSlotInd(Array(50).fill(-1)));
  }, []);

  useEffect(() => {
    setActiveTimeSlotInd(Array(50).fill(-1));
  }, [theaterDetails]);
  const handleProceed = () => {
    const theaterCode = selectedTheater?.code;
    const selectedLocation = selectedTheater?.location;

    // Get the current URL and split it into path parts
    let currentUrl = window.location.pathname;

    // Check if the location exists in the URL
    const locationPattern = /private-celebration-venue-in-([a-zA-Z0-9%20\-]+)/; // Regex to match the location part
    const locationMatch = currentUrl.match(locationPattern);

    // If the location is present, do not remove it, just truncate the rest of the URL after the location
    if (locationMatch) {
        // Extract up to the location part (including the location)
        currentUrl = currentUrl.substring(0, currentUrl.indexOf(locationMatch[0]) + locationMatch[0].length);
    } else {
        // If location is not found, append the location if it's available
        if (selectedLocation) {
            currentUrl = `${currentUrl}/private-celebration-venue-in-${encodeURIComponent(selectedLocation).replace(/\+/g, "%20")}`;
        }
    }

    // Remove the query parameters (if present)
    const urlWithoutQuery = currentUrl.split('?')[0];

    // Construct the final URL by adding /booking_overview/${theaterCode} to the URL
    const finalUrl = `${urlWithoutQuery}/booking_overview/${theaterCode}`;

    // Navigate to the updated URL
    navigate(finalUrl);
};

  return (
    <>
     <HelmetProvider>
        {selectedLocation?.locationName && (
          <Helmet
          script={[
            helmetJsonLdProp<Place>({
              "@context": "https://schema.org",
              "@type": "Place",
              "name": `Binge Town Theater Selection in ${locationN}`,
              "description": `Select your preferred Theater in ${locationN}`,
              "url": `https://thebingetown.com/private-theaters-in-bangalore/${locationN}`
            }),
          ]}
          >
            <title>
              {seoStrings[locationN as keyof typeof seoStrings]["title"]}
            </title>
            <meta
              name="description"
              content={
                seoStrings[locationN as keyof typeof seoStrings]["description"]
              }
            />
            <meta
              name="keywords"
              content={
                seoStrings[locationN as keyof typeof seoStrings]["keyword"]
              }
            />
          </Helmet>
        )}
      </HelmetProvider>
      <S.RightViewSectionContainer>
        
          <Header />
        <S.RightViewSectionView>        
            <S.BackButtonContainer onClick={() => navigate(-1)}>
              <HiArrowLeft size={24} />
              <S.BackButtonTxt>Back</S.BackButtonTxt>
            </S.BackButtonContainer>
            <S.WidgetHeadingContainer>
              <S.Heading>
                <h1 style={{ fontFamily: 'inherit', fontSize: 'unset', paddingLeft: "4vw" }}> {theaterCount} Theaters in {selectedLocation?.locationName} </h1>
              </S.Heading>
            <S.WidgetContainer>
              <S.DateLocationContainer>
                <S.DateLocationRow>
                  <S.LocationText>{theaterDetails[0]?.location}</S.LocationText>
                  <S.DateText>{selectedTheaterDate.day} {monthNames[selectedTheaterDate.month - 1]}</S.DateText>
                </S.DateLocationRow>
                <S.UpdateButton onClick={() => setIsOpenModal(true)}>Update</S.UpdateButton>
              </S.DateLocationContainer>
            </S.WidgetContainer>
          </S.WidgetHeadingContainer>
          <S.TheaterContainer>
            {theaterDetails &&
              theaterDetails.length > 0 &&
              theaterDetails.map((theater: any, index: any) => {
                return <Theaters key={theater?.code} theaterDetails={theater} index={index} />;
              })}
          </S.TheaterContainer>
          {
            !isIphone &&
            <Suspense fallback={<div>Loading theaters...</div>}>
              <OtherTheaters selectedLocation={selectedLocation} />
            </Suspense>
          }
        </S.RightViewSectionView>
      </S.RightViewSectionContainer>
      {/* <S.RightViewLinkContainer>
        <S.LinkHeading><b>Other branches in {selectedCity}</b></S.LinkHeading>
        <S.LinkContainer>
          {serviceAvailableAtCities && 
            serviceAvailableAtCities?.length > 0 &&
            serviceAvailableAtCities?.map((city:any)=>{
              if(city?.cityName?.toLowerCase() === selectedCity?.toLowerCase()){
                return (city?.locations?.length > 0 &&
                city?.locations.map((location:any)=> {
                  if(location?.locationName !== selectedLocation?.locationName){
                  return (<>
                  <Button style={{backgroundColor:"#ffffff", width: "48%", margin:"1%", whiteSpace: "nowrap", textAlign: "center", padding: "0.5rem 0", textTransform: "none"}}>
                    <Link
                        to={`/private-theaters-in-${selectedCity.toLowerCase()}/${location?.locationName.toLowerCase()}`}
                        title={`${location?.locationName} Page | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town`}
                        style={{ color: "#34255C", textAlign: 'center', textDecoration: "underline"}}   
                        onClick={()=>{dispatch(setSelectedLocation(location))}}                   
                    >
                      {location?.locationName}
                    </Link></Button>
                    </>);
                  }
                }) 
              )
            }
            }
          )}
        </S.LinkContainer>
      </S.RightViewLinkContainer> */}
      {isMweb && <HaveDoubts />}
      <S.Footer>
        <Carousel
          interval={3000}
          navButtonsAlwaysVisible={true}
          indicatorContainerProps={{
            style: {
              display: "none",
            },
          }}
        >
          {seoFooterContent &&
            seoFooterContent[selectedLocation?.locationName as keyof typeof seoFooterContent] &&
            seoFooterContent[selectedLocation?.locationName as keyof typeof seoFooterContent]
              .map((item: any, i: any) => {
                return (
                  <S.CarouselView key={`carousel_${i}`}>
                    <h2><S.FooterText>{item.name}</S.FooterText></h2>
                    <h3><S.FooterSubText>{item.description}</S.FooterSubText></h3>
                  </S.CarouselView>
                );
              })}
        </Carousel>
      </S.Footer>
      {slotBooked && <S.PayBookButton>
        <S.RemainingAmountText>Remaining amount to be paid at the venue</S.RemainingAmountText>
        <S.BookButtonContainer onClick={handleProceed}>
          Pay &#8377;{theaterDetails?.[0]?.advance} To Reserve
        </S.BookButtonContainer>
      </S.PayBookButton>}
      {isOpenModal  &&<EditLocationModal isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal}/>}
    </>
  );
};
export default RightViewSection;
