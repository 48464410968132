
import styled from "styled-components";
import { HiArrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import * as S from "./CakePage.style";
import Header from "../../Home/screens/Header/Header";
import CopyRight from "../../Home/screens/CopyRight/CopyRight";
import {  useSelector, useDispatch } from "react-redux";
import {PROD, VERSION} from "../../../endPointsConfig";
import {
  setSelectedTheater,
  setTheaterInfo,
} from "../../../redux/actions/bookingActions";
import {
    
    getSelectedTheater,
  } from "../../../redux/selectors/bookingSelector";

const ImageContainer = styled.div`
  background-color: #ffffff;
  border-radius: 50%;
  width: 160px;
  height: 160px;
  box-shadow: 0px 10px 35px 0px #575c8a1f;

  @media only screen and (max-width: 786px) {
    width: 130px;
    height: 130px;
  } 
`;
interface FilterTagProps {
  isSelected: boolean;
}
 const FilterTag = styled.div<FilterTagProps>`
  background-color: ${(props) => (props.isSelected ? '#34265C' : '#e0e7ff')};
  border-radius: 15px;
  padding: 5px 10px;
  cursor:pointer;`
  const FilterTagText = styled.div<FilterTagProps>`
  color:${(props) => (props.isSelected ? '#ffffff' : '#000000')};
  font-size: 14px;
`
export interface Cake {
  name: string;
  price: number;
  iconUrl: string;
  index: number;
  category: string;
  details: {
    unit: string;
    quantity: number;
    price: number;
  }[];
}
export interface ActiveVariant {
  name: string;
  price: number;
  quantity: number;
  unit: string;
}

const CakePage = () => {
  let navigate = useNavigate();
  const [selectedTag, setSelectedTag] = useState('Standard');
  const selectedTheater = useSelector(getSelectedTheater);
  const CAKES = selectedTheater?.cake || [];
  const dispatch = useDispatch();
  useEffect(()=>{
    fetchLocationInfo();
  },[]);
  const fetchLocationInfo = () => {
    const sessionId = localStorage.getItem("tbt_njkwe_sessionId") || "";
    fetch(`${PROD}/webConfigv2?location=KO&newWeb=${VERSION}`,
          {
            method: "GET",
            headers: {
                "sessionId": sessionId  // Fallback value ensures this is always a string
            }
        }
        )
        .then((response) => {
            return response.json();
        })
        .then((response) => {
            dispatch(setTheaterInfo(response));
            dispatch(setSelectedTheater(response[0]));
        })
        .catch((error: any) => {
            console.log(error);
        });
  }
  return (
    <>
    <title>The Binge Town | Cake Selection</title>
      <Header/>
      <S.BookingOverviewContainer>
        <S.LeftViewSectionContainer>
          <S.BackButtonContainer onClick={() => navigate(-1)}>
            <HiArrowLeft size={24} />
            <S.BackButtonTxt>Back</S.BackButtonTxt>
          </S.BackButtonContainer>

          <S.Overview>
            <S.HeadingContainer>
              <S.HeadingTxt>Cake Menu</S.HeadingTxt>
              <S.OptionalContainer>(Premium Eggless Cakes)</S.OptionalContainer>
            </S.HeadingContainer>
            <S.FilterContainer>
              <FilterTag
                isSelected={selectedTag === 'Standard'}
                onClick={() => setSelectedTag('Standard')}
              >
                <FilterTagText
                isSelected={selectedTag === 'Standard'}>Standard Cakes</FilterTagText>
              </FilterTag>
              <FilterTag
                isSelected={selectedTag === 'Premium'}
                onClick={() => setSelectedTag('Premium')}
              >
                <FilterTagText
                isSelected={selectedTag === 'Premium'}>Premium Cakes</FilterTagText>
              </FilterTag>
            </S.FilterContainer>
            <S.CakeView>
            {CAKES && CAKES.length > 0 && (
                CAKES.filter((cake: Cake) => cake.category === selectedTag.toLowerCase()) // Filter based on selectedTag
                .map((cake: Cake) => {
                  let str2 = "";
                  let str1 = `${cake?.details[0].price} (${cake?.details[0].quantity} ${cake?.details[0].unit})`;
                  if(cake?.details[1]){
                    str2 = `${cake?.details[1].price} (${cake?.details[1].quantity} ${cake?.details[1].unit})`
                  }
                  return (<S.OccasionView key={cake.name}>
                    <ImageContainer>
                      <img
                          src={cake.iconUrl}
                          alt={cake.name}
                          title={cake.name}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            overflow: "hidden",
                            borderRadius: "50%"
                          }}
                      />
                    </ImageContainer>
                    <S.OccasionTitle>{cake.name}</S.OccasionTitle>
                    <S.OccasionPrice>&#8377;{str1}</S.OccasionPrice>
                    {str2 !== "" ? <S.OccasionPrice>&#8377;{str2}</S.OccasionPrice> : <></>}
                  </S.OccasionView>)
                })
            )}
            </S.CakeView>

            <S.OptionalContainer>(Note:- The cake images provided are for representation purposes only; actual cakes may vary.)</S.OptionalContainer>
          </S.Overview>

          <S.MwebFooterContainer>
            
          </S.MwebFooterContainer>
        </S.LeftViewSectionContainer>
      </S.BookingOverviewContainer>
      <CopyRight/>
    </>
  );
};

export default CakePage;