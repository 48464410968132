import styled from "styled-components";
import Banner from "../Home/screens/Banner/Banner";
import CopyRight from "../Home/screens/CopyRight/CopyRight";
import Footer from "../Home/screens/Footer/Footer";
import Header from "../Home/screens/Header/Header";
import waitlist from "../../assets/images/waitlist.svg";
import Select from "react-select";
import Input from "@mui/joy/Input";
import { IoCloseOutline } from "react-icons/io5";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCities,
  serviceAvailableAtCitiesSelector,
} from "../../redux/selectors/webConfigSelector";
import QuantityInput from "../Booking/screens/BookingOverview/components/BookingDetails/QuantityInput";
import { fetchWebConfig } from "../../apis/ConfigAPI";
import { setWebConfig } from "../../redux/actions/webConfigActions";
import { useNavigate } from "react-router";
import { PROD } from "../../endPointsConfig";
import * as S from "./WaitListForm.style";

const BookNowBtn = styled.button<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  background-color: ${(props) => (props.disabled ? "#E5E5E5" : "#34265c")};
  color: ${(props) => (props.disabled ? "#000000" : "#ffffff")};
  // width: 50rem;
  height: 3rem;
  border: none;
  border-radius: 64px;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  font-family: Montserrat;
  margin-top: 1.5rem;
`;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "16px solid #fffff",
  borderRadius: 4,
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  fontFamily: "Montserrat",
};

const WaitListForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cities = useSelector(getCities) || [];
  const citiesLocationData = useSelector(serviceAvailableAtCitiesSelector);
  const [citiesOptions, setCitiesOptions] = useState<any>([]);
  const [activeCity, setActiveCity] = useState("");
  const [activeLocation, setActiveLocation] = useState("");
  const [locationsOptions, setLocationsOptions] = useState<any>([]) || [];
  const [numberOfPeople, setNumberOfpeople] = useState<any>(0);
  const [error, setError] = useState("") || "";
  const [isFormValid, setFormValid] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDone = () => {
    navigate("/");
  };

  const handleNumberOfPeople = (numberOfPeople: Number) => {
    setNumberOfpeople(numberOfPeople);
  };

  const [formData, setFormData] = useState({
    bookingName: "",
    whatsappNumber: "",
    date: "",
  });

  useEffect(() => {
    if (cities.length === 0) {
      fetchWebConfig()
        .then((response) => {
          dispatch(setWebConfig(response));
        })
        .catch((error) => {
          setError(error);
        });
    }
  }, []);

  useEffect(() => {
    let options = [];
    for (let i = 0; i < cities.length; i++) {
      options.push({
        label: `${cities[i].charAt(0).toUpperCase()}${cities[i].slice(1)}`,
        value: `${cities[i].charAt(0).toUpperCase()}${cities[i].slice(1)}`,
      });
    }
    setCitiesOptions(options);
  }, [cities]);

  useEffect(() => {
    for (let city in citiesLocationData) {
      if (
        citiesLocationData[city]?.cityName?.toLowerCase() ===
        activeCity?.toLowerCase()
      ) {
        let tempLocations = [];
        let cityLocations = citiesLocationData[city]?.locations;
        for (let i = 0; i < cityLocations.length; i++) {
          tempLocations.push({
            label: cityLocations[i].locationName,
            value: cityLocations[i].locationName,
          });
        }
        setLocationsOptions(tempLocations);
      }
    }
  }, [activeCity]);

  useEffect(() => {
    if (
      formData?.bookingName.length > 0 &&
      formData?.date &&
      formData?.whatsappNumber.length > 0 &&
      activeCity &&
      activeLocation
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [formData]);

  const handleCityChange = (e: any) => {
    setActiveCity(e?.value);
  };

  const handleLocationChange = (e: any) => {
    setActiveLocation(e?.value);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleJoinWaitlist = () => {
    const sessionId = localStorage.getItem("tbt_njkwe_sessionId") || "";
    fetch(`${PROD}/postWaitList`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "sessionId": sessionId 
      },
      body: JSON.stringify({
        city: activeCity,
        date: formData?.date,
        name: formData?.bookingName,
        numOfPeople: numberOfPeople,
        phoneNum: formData?.whatsappNumber,
        theatre: activeLocation,
      }),
    })
      .then((response) => {
        handleOpen();
        return response.json();
      })
      .then((response) => {})
      .catch((err) => {
        setError(err);
      });
  };
  const isMweb = useMediaQuery("(max-width:786px)");

  return (
    <>
    <title>The Binge Town | Waitlist Form</title>
      <Header />
      <Banner />

      <S.WaitListFormContainer>
        <S.RightHalf>
          <S.Heading>Join our waitlist</S.Heading>
          <S.FormContainer>
            <S.FormInstruction>
              Please fill out this form and we will contact you shortly
            </S.FormInstruction>
            <form>
              <S.InputContainer>
                <S.LabelBox htmlFor="bookingName">
                  Booking Name &#x2a;
                </S.LabelBox>
                <Input
                  variant="outlined"
                  placeholder="Type here"
                  size="lg"
                  required
                  name="bookingName"
                  value={formData?.bookingName}
                  onChange={handleChange}
                  style={{
                    margin: "0.7rem 0",
                    borderRadius: "12px",
                    padding: "0.8rem",
                    fontFamily: "Montserrat",
                  }}
                />
              </S.InputContainer>

              <S.InputContainer>
                <S.LabelBox htmlFor="whatsappNumber">
                  WhatsApp Number &#x2a;
                </S.LabelBox>
                <Input
                  variant="outlined"
                  placeholder="Type here"
                  size="lg"
                  required
                  value={formData?.whatsappNumber}
                  name="whatsappNumber"
                  onChange={handleChange}
                  style={{
                    margin: "0.7rem 0",
                    borderRadius: "12px",
                    padding: "0.8rem",
                    fontFamily: "Montserrat",
                  }}
                />
              </S.InputContainer>

              <S.RowContainer>
                <S.InputContainer>
                  <S.LabelBox htmlFor="city">City &#x2a;</S.LabelBox>
                  <Select
                    placeholder="Select here"
                    options={citiesOptions}
                    onChange={handleCityChange}
                    name="city"
                    styles={{
                      menuList: (styles) => ({
                        ...styles,
                        padding: 0,
                      }),
                      control: (styles) => ({
                        ...styles,
                        backgroundColor: "white",
                        flex: 1,
                        padding: "0.5rem 0",
                        margin: "0.7rem 0",
                        borderRadius: "12px",
                      }),
                      option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                      ) => {
                        return {
                          // ...styles,
                          backgroundColor: "#edecea",
                          padding: "0.5rem",
                          height: "2rem",
                          display: "flex",
                          alignItems: "center",
                          borderBotom: "1px solid #cacaca",
                        };
                      },
                    }}
                  />
                </S.InputContainer>
                <S.InputContainer>
                  <S.LabelBox htmlFor="location">Location &#x2a;</S.LabelBox>
                  <Select
                    placeholder="Select here"
                    options={locationsOptions}
                    onChange={handleLocationChange}
                    name="location"
                    styles={{
                      menuList: (styles) => ({
                        ...styles,
                        padding: 0,
                      }),
                      control: (styles) => ({
                        ...styles,
                        backgroundColor: "white",
                        flex: 1,
                        padding: "0.5rem 0",
                        margin: "0.7rem 0",
                        borderRadius: "12px",
                      }),
                      option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                      ) => {
                        return {
                          // ...styles,
                          backgroundColor: "#edecea",
                          padding: "0.5rem",
                          height: "2rem",
                          display: "flex",
                          alignItems: "center",
                          borderBotom: "1px solid #cacaca",
                        };
                      },
                    }}
                  />
                </S.InputContainer>
              </S.RowContainer>

              <S.RowContainer>
                <S.InputContainer>
                  <S.LabelBox htmlFor="date">Date &#x2a;</S.LabelBox>
                  <Input
                    variant="outlined"
                    placeholder="dd/mm/yyyy"
                    size="lg"
                    onChange={handleChange}
                    name="date"
                    style={{
                      margin: "0.7rem 0",
                      padding: "0.8rem",
                      borderRadius: "12px",
                    }}
                    required
                    type="date"
                    slotProps={{
                      input: {
                        min: "2024-04-30",
                        max: "2030-06-14",
                      },
                    }}
                  />
                </S.InputContainer>

                <S.InputContainer>
                  <S.LabelBox htmlFor="location">Number of people</S.LabelBox>
                  <S.NumberOfPeopleContainer>
                  <QuantityInput
                    min={0}
                    max={12}
                    defaultValue={numberOfPeople}
                    name="numberOfPeople"
                    setNumberOfpeople={handleNumberOfPeople}
                  />
                  </S.NumberOfPeopleContainer>
                </S.InputContainer>
              </S.RowContainer>
            </form>
          </S.FormContainer>

          <S.MwebFormContainer>
            <S.FormInstruction>
              Please fill out this form and we will contact you shortly
            </S.FormInstruction>
            <form>
              <S.InputContainer>
                <S.LabelBox htmlFor="bookingName">
                  Booking Name &#x2a;
                </S.LabelBox>
                <Input
                  variant="outlined"
                  placeholder="Type here"
                  size="lg"
                  required
                  name="bookingName"
                  value={formData?.bookingName}
                  onChange={handleChange}
                  style={{
                    margin: "0.7rem 0",
                    borderRadius: "12px",
                    padding: "0.8rem",
                    fontFamily: "Montserrat",
                  }}
                />
              </S.InputContainer>

              <S.InputContainer>
                <S.LabelBox htmlFor="whatsappNumber">
                  WhatsApp Number &#x2a;
                </S.LabelBox>
                <Input
                  variant="outlined"
                  placeholder="Type here"
                  size="lg"
                  required
                  value={formData?.whatsappNumber}
                  name="whatsappNumber"
                  onChange={handleChange}
                  style={{
                    margin: "0.7rem 0",
                    borderRadius: "12px",
                    padding: "0.8rem",
                    fontFamily: "Montserrat",
                  }}
                />
              </S.InputContainer>

              <S.InputContainer>
                <S.LabelBox htmlFor="city">City &#x2a;</S.LabelBox>
                <Select
                  placeholder="Select here"
                  options={citiesOptions}
                  onChange={handleCityChange}
                  name="city"
                  styles={{
                    menuList: (styles) => ({
                      ...styles,
                      padding: 0,
                    }),
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: "white",
                      flex: 1,
                      padding: "0.5rem 0",
                      margin: "0.7rem 0",
                      borderRadius: "12px",
                    }),
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      return {
                        // ...styles,
                        backgroundColor: "#edecea",
                        padding: "0.5rem",
                        height: "2rem",
                        display: "flex",
                        alignItems: "center",
                        borderBotom: "1px solid #cacaca",
                      };
                    },
                  }}
                />
              </S.InputContainer>
              <S.InputContainer>
                <S.LabelBox htmlFor="location">Location &#x2a;</S.LabelBox>
                <Select
                  placeholder="Select here"
                  options={locationsOptions}
                  onChange={handleLocationChange}
                  name="location"
                  styles={{
                    menuList: (styles) => ({
                      ...styles,
                      padding: 0,
                    }),
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: "white",
                      flex: 1,
                      padding: "0.5rem 0",
                      margin: "0.7rem 0",
                      borderRadius: "12px",
                    }),
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      return {
                        // ...styles,
                        backgroundColor: "#edecea",
                        padding: "0.5rem",
                        height: "2rem",
                        display: "flex",
                        alignItems: "center",
                        borderBotom: "1px solid #cacaca",
                      };
                    },
                  }}
                />
              </S.InputContainer>

              <S.InputContainer>
                <S.LabelBox htmlFor="date">Date &#x2a;</S.LabelBox>
                <Input
                  variant="outlined"
                  placeholder="dd/mm/yyyy"
                  size="lg"
                  onChange={handleChange}
                  name="date"
                  style={{
                    margin: "0.7rem 0",
                    padding: "0.8rem",
                    borderRadius: "12px",
                  }}
                  required
                  type="date"
                  slotProps={{
                    input: {
                      min: "2024-04-30",
                      max: "2030-06-14",
                    },
                  }}
                />
              </S.InputContainer>

              <S.InputContainer>
                <S.LabelBox htmlFor="location">Number of people</S.LabelBox>
                <S.NumberOfPeopleContainer>
                  <QuantityInput
                    min={0}
                    max={12}
                    defaultValue={numberOfPeople}
                    name="numberOfPeople"
                    setNumberOfpeople={handleNumberOfPeople}
                  />
                </S.NumberOfPeopleContainer>
              </S.InputContainer>
            </form>
          </S.MwebFormContainer>
          <BookNowBtn onClick={handleJoinWaitlist} disabled={!isFormValid}>
            Join our waitlist
          </BookNowBtn>
        </S.RightHalf>
        <S.LeftHalf>
          <img
            src={waitlist}
            alt="waiting"
            title="waiting"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              overflow: "hidden",
            }}
          />
        </S.LeftHalf>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style }} width={isMweb ? "70%" : "40%"}>
            <S.ModalContainer>
              <S.ModalHeading>
                <S.ModalHeadingTxt></S.ModalHeadingTxt>
                <IoCloseOutline size={24} onClick={handleClose} />
              </S.ModalHeading>
              <S.ModalDescription>
                Thanks for your details. We will contact you in case of any
                availability.
              </S.ModalDescription>
              <BookNowBtn onClick={handleDone}>Done</BookNowBtn>
            </S.ModalContainer>
          </Box>
        </Modal>
      </S.WaitListFormContainer>

      <Footer />
      <CopyRight />
    </>
  );
};
export default WaitListForm;
