import styled from "styled-components";
import Header from "../../../Home/screens/Header/Header";
import { IoCloseOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import dateFormat from "dateformat";
import useMediaQuery from "@mui/material/useMediaQuery";
import Footer from "../../../Home/screens/Footer/Footer";
import { ImWhatsapp } from "react-icons/im";
import ReactLoading from "react-loading";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import CopyRight from "../../../Home/screens/CopyRight/CopyRight";
import { FaRegClock } from "react-icons/fa6";
import { MdOutlineCalendarToday } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getUpdateBookingDetails } from "../../../../redux/selectors/blogsSelector";
import { useEffect, useState } from "react";
import BookingCancelled from "../../../../assets/images/BookingCancelled.svg";
import error_icon from "../../../../assets/images/error_icon.webp";
import { PROD } from "../../../../endPointsConfig";
import DecorationIcon from "../../../../assets/images/DecorationIcon.png";
import {
  resetActiveVariants,
  resetActiveDecorations,
  resetActiveGifts,
  resetActiveServices,
  resetBookingDetails,
  resetBookingSummaryAddons,
  resetExtraNumberOfPeople,
  resetSelectedOccasion,
  resetSelectedTheaterDate,
  resetSelectedTheaterTime,
} from "../../../../redux/actions/bookingActions";
import { getHoursTillBooking } from "./utils";
import * as S from "./MyBooking.style";

export const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "16px solid #fffff",
  borderRadius: 4,
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  fontFamily: "Montserrat",
};

const LoadingContainer = styled.div`
  width: 100%;
  height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MyBooking = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bookingDetails = useSelector(getUpdateBookingDetails) || [];
  const [bookingId, setBookingId] = useState("");
  const [open, setOpen] = useState(false);
  const [boookingCancelledOpen, setBoookingCancelledOpen] = useState(false);
  const [cancelBookingError, setCancelBookingError] = useState(false);
  const [error, setError] = useState("") || "";
  const [updateFailedModal, setUpdateFailedModal] = useState(false);
  const [cancelFailedModal, setCancelFailedModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState({ "": "" });

  useEffect(() => {
    if (bookingDetails && bookingDetails.length > 0) {
      bookingDetails.map((bookingDetail: any) => {
        let code = bookingDetail?.code.split("_");
        let theaterCode = code[0] + "_" + code[1];
        const sessionId = localStorage.getItem("tbt_njkwe_sessionId") || "";
        fetch(`${PROD}/getMediaUrls?theaterCode=${theaterCode}`,{
          method: "GET",
        headers: {
            "sessionId": sessionId  
        }
        })
          .then((response) => {
            return response.json();
          })
          .then((response) => {
            response.map((mediaData: any) => {
              if (
                mediaData?.type === "image" &&
                parseInt(mediaData?.pos) === 1
              ) {
                setImageUrl((prevState) => ({
                  ...prevState,
                  [bookingDetail.id]: mediaData?.url,
                }));
              }
            });
          })
          .catch((err) => {
            setError(err);
          });
      });
    }
  }, []);

  const isMweb = useMediaQuery("(max-width:786px)");

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateFailedModalClose = () => {
    setUpdateFailedModal(false);
  };

  const handleCancelFailedModalClose = () => {
    setCancelFailedModal(false);
  };

  const handleBookingCancelledOpen = () => {
    setBoookingCancelledOpen(true);
  };

  const handleCancelBooking = (bookingDetails: any) => {
    const diff = getHoursTillBooking(bookingDetails);
    if (diff < 72) {
      setCancelFailedModal(true);
    } else {
      setOpen(true);
      setBookingId(bookingDetails?.id);
    }
  };

  const handleFinalCancelBooking = () => {
    setIsLoading(true);
    const sessionId = localStorage.getItem("tbt_njkwe_sessionId") || "";
    fetch(`${PROD}/cancelBooking?id=${bookingId}`,{
        headers: {
            "sessionId": sessionId  // Fallback value ensures this is always a string
        }
    })
      .then((response) => {
        return response.json();
      })
      .then((response: any) => {
        if (!response?.msg) {
          setCancelBookingError(true);
        }
        setIsLoading(false);
        handleBookingCancelledOpen();
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err);
        handleBookingCancelledOpen();
        setCancelBookingError(true);
      });
  };

  const goBackToHome = () => {
    navigate("/");
  };

  useEffect(() => {
    dispatch(resetActiveVariants());
    dispatch(resetBookingSummaryAddons());
    dispatch(resetActiveDecorations());
    dispatch(resetActiveGifts());
    dispatch(resetActiveServices());
    dispatch(resetExtraNumberOfPeople());
    dispatch(resetBookingDetails());
    dispatch(resetSelectedTheaterTime());
    dispatch(resetSelectedTheaterDate());
    dispatch(resetSelectedOccasion());
  }, []);

  const handleUpdateBooking = (updateBookingDetail: any) => {
    const diff = getHoursTillBooking(updateBookingDetail);
    if (diff < 48) {
      setUpdateFailedModal(true);
    } else {
      navigate(`/booking_overview/${updateBookingDetail?.id}`);
    }
  };

  const chatWithUs = () => {
    window.open(
      "https://api.whatsapp.com/send/?phone=918618976974&text&type=phone_number&app_absent=0"
    );
  };
  return (
    <>
    <title>The Binge Town | My Bookings</title>
      <Header />
      <S.MyBookingOverviewContainer>
        <S.Heading>
          <S.HeadingTxt>My Bookings</S.HeadingTxt>
        </S.Heading>
        {isLoading ? (
          <LoadingContainer>
            <ReactLoading type="spin" color="#34255c" height="5%" width="5%" />
          </LoadingContainer>
        ) : (
          <>
            <S.BookingsContainer>
              {bookingDetails && bookingDetails.length > 0 ? (
                bookingDetails.map((bookingDetail: any) => {
                  return (
                    <S.BookingContainer key={bookingDetail?.id}>
                      <S.ImgContainer>
                        <img
                          src={
                            imageUrl[bookingDetail?.id as keyof typeof imageUrl]
                          }
                          alt="theaterImage"
                          title="theaterImage"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            overflow: "hidden",
                          }}
                        />
                      </S.ImgContainer>

                      <S.TheaterContainer>
                        {bookingDetail?.theatre}, {bookingDetail?.location}
                      </S.TheaterContainer>
                      <S.DateContainer>
                        <S.IconContainer>
                          <MdOutlineCalendarToday size={24} color="#34255C" />
                        </S.IconContainer>
                        <S.Date>
                          {dateFormat(bookingDetail?.date, "mmmm dS, yyyy")}
                        </S.Date>
                      </S.DateContainer>
                      <S.DateContainer>
                        <S.IconContainer>
                          <FaRegClock size={24} color="#34255C" />
                        </S.IconContainer>
                        <S.Date>{bookingDetail?.time}</S.Date>
                      </S.DateContainer>
                      <S.DateContainer>
                        <S.IconContainer>
                          <img
                            alt="decorationIcon"
                            title="decorationIcon"
                            src={DecorationIcon}
                            style={{ width: "24px", height: "24px" }}
                          />
                        </S.IconContainer>
                        <S.Date>Decoration : {bookingDetail?.decorType}</S.Date>
                      </S.DateContainer>
                      <S.ButtonView
                        onClick={() => handleUpdateBooking(bookingDetail)}
                      >
                        Update Booking
                      </S.ButtonView>
                      <S.CancelBookingContainer
                        onClick={() => handleCancelBooking(bookingDetail)}
                      >
                        Cancel Booking
                      </S.CancelBookingContainer>
                    </S.BookingContainer>
                  );
                })
              ) : (
                <>
                  <S.ErrorContainer style={{ color: "red" }}>
                    No active bookings with this number
                  </S.ErrorContainer>
                </>
              )}
            </S.BookingsContainer>
            <S.FooterContainer>
              <S.BookNowBtn onClick={chatWithUs} type="button">
                <ImWhatsapp size={24} /> Chat with us
              </S.BookNowBtn>
            </S.FooterContainer>

            <Modal
              open={updateFailedModal}
              onClose={handleUpdateFailedModalClose}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box sx={{ ...style, width: isMweb ? 270 : 400 }}>
                <S.ModalContainer>
                  <S.ModalHeading1>
                    <img
                      alt="bookingCancelledIcon"
                      title="bookingCancelledIcon"
                      src={error_icon}
                      style={{ width: "40px", height: "40px" }}
                    />
                  </S.ModalHeading1>
                  <S.ModalDescription>
                    Booking cannot be modified from here because less than 48
                    hrs are left. Please contact customer care.
                  </S.ModalDescription>
                  <S.ButtonView onClick={goBackToHome}>Go to Home</S.ButtonView>
                </S.ModalContainer>
              </Box>
            </Modal>

            <Modal
              open={cancelFailedModal}
              onClose={handleCancelFailedModalClose}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box sx={{ ...style, width: isMweb ? 270 : 400 }}>
                <S.ModalContainer>
                  <S.ModalHeading1>
                    <img
                      alt="bookingCancelledIcon"
                      title="bookingCancelledIcon"
                      src={error_icon}
                      style={{ width: "40px", height: "40px" }}
                    />
                  </S.ModalHeading1>
                  <S.ModalDescription>
                    Booking cannot be cancelled from here because less than 72
                    hrs are left. Please contact customer care.
                  </S.ModalDescription>
                  <S.ButtonView onClick={goBackToHome}>Go to Home</S.ButtonView>
                </S.ModalContainer>
              </Box>
            </Modal>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box sx={{ ...style, width: isMweb ? 270 : 400 }}>
                <S.ModalContainer>
                  <S.ModalHeading>
                    <S.ModalHeadingTxt>Confirm cancellation</S.ModalHeadingTxt>
                    <IoCloseOutline size={24} onClick={handleClose} />
                  </S.ModalHeading>
                  <S.ModalDescription>
                    Advance amount will be fully refunded within 5 - 7 days
                  </S.ModalDescription>
                  <S.ButtonView onClick={handleFinalCancelBooking}>
                    Cancel Booking
                  </S.ButtonView>
                </S.ModalContainer>
              </Box>
            </Modal>

            <Modal
              open={boookingCancelledOpen}
              onClose={goBackToHome}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box sx={{ ...style, width: isMweb ? 270 : 400 }}>
                <S.ModalContainer>
                  <S.ModalHeading1>
                    <img
                      alt="bookingCancelledIcon"
                      title="bookingCancelledIcon"
                      src={cancelBookingError ? error_icon : BookingCancelled}
                      style={{ width: "40px", height: "40px" }}
                    />
                  </S.ModalHeading1>
                  <S.ModalSubTxt>
                    {cancelBookingError
                      ? "Failed to cancel booking. Please try later."
                      : "Booking cancelled succefully! Refund shall be completed in 5-7 days."}
                  </S.ModalSubTxt>
                  <S.ButtonView onClick={goBackToHome}>Go to Home</S.ButtonView>
                </S.ModalContainer>
              </Box>
            </Modal>
          </>
        )}
      </S.MyBookingOverviewContainer>

      <Footer />
      <CopyRight />
    </>
  );
};
export default MyBooking;
