import { PROD, VERSION } from "../endPointsConfig";

export const fetchWebConfig = () => {
  const sessionId = localStorage.getItem("tbt_njkwe_sessionId") || "";
  return new Promise((resolve, reject) => {
    fetch(`${PROD}/webConfigv2?newWeb=${VERSION}`,{
            method: "GET",
            headers: {
                "sessionId": sessionId  // Fallback value ensures this is always a string
            }
        }
      )
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {});
  });
};

export const fetchBlogs = () => {
  const sessionId = localStorage.getItem("tbt_njkwe_sessionId") || "";
  return new Promise((resolve, reject) => {
    fetch(`${PROD}/getBlogs`,{
      method: "GET",
            headers: {
                "sessionId": sessionId  // Fallback value ensures this is always a string
            }
        }
      )
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postOrder = (order_id) => {
    const sessionId = localStorage.getItem("tbt_njkwe_sessionId") || "";
    return new Promise((resolve, reject) => {
        fetch(`${PROD}/getOrderDetails?order_id=${order_id}`)
            .then((response) => response.json())
            .then((data) => {
                return resolve(data);
            })
            .catch((err) => {
                console.log(err.message);
                return resolve(false);
            });
    });
};
