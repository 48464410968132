import styled from "styled-components";
export const ModalOverlay = styled.div`
  position: sticky;
  bottom:0;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000; /* Ensure it's above other content */
  @media only screen and (max-width: 768px) {
    align-items: flex-start;
  }
`;
export const ModalContent = styled.div`
position: sticky;
width:400px;
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1001; /* Ensure it's above the overlay */
  @media only screen and (max-width: 768px) {
    width:100%;
  }
`;
export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;

  &:hover {
    color: #000;
  }
`;
export const MenuItems = styled.div`
    color: grey;
    font-size: 14px;
    font-weight: 500;
`
export const Heading = styled.div`
    color: black;
    font-size: 14px;
    font-weight: 600;
`
export const GreyLine = styled.hr`
 height: 0.5px;
 color: grey;
 width: 100%;
`
export const FoodName = styled.div`
color: black;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;

`
export const FoodOptions = styled.div`
    background-color: lightgrey;
    border-radius: 8px;
    padding: 10px;
    margin: 5px 0px;
`
export const OptionRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const OptionText = styled.div`
    color: black;
    font-size: 20px;
    line-height:35px;
    font-weight: 400;

`
export const PriceText = styled.div`
    color: black;
    font-size: 20px;
    line-height:35px;
    font-weight: 400;
    text-align: end;
`
export const PriceButtonContainer = styled.div`
    display: flex;
  align-items: center; // Center the text and icon
  gap: 5px; 
`
export const BookNowBtn = styled.button`
  display: flex;
  justify-content: center;
  background-color: #34265c;
  color: white;
  border: none;
  border-radius: 20px;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  padding: 10px;
  font-family: Montserrat;
  width:100%;
  margin-top:10px;
  line-height: 30px;
`;