import * as S from "./CustomizationModal.style";
import { IoClose } from "react-icons/io5";
import { FC, useState, useEffect } from "react";
import { ActiveVariant, Cake } from "../CakeSelection";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { IoRadioButtonOn, IoRadioButtonOff } from "react-icons/io5";
import {
  getActiveVariants,
  getBookingSummary
} from "../../../../../../../redux/selectors/bookingSelector";
import {
  setActiveVariants,
  setSubtotalAmount,
  setBookingSummaryCake
} from "../../../../../../../redux/actions/bookingActions";
import {store} from "../../../../../../../store";

// Define the type for the props
interface CustomizationModalProps {
  isOpen: boolean;         // Boolean to show or hide modal
  onClose: () => void;  
  cake: Cake;   // Function to handle close event
}

const CustomizationModal: FC<CustomizationModalProps> = ({ isOpen, onClose, cake }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const activeVariantsSelector = useSelector(getActiveVariants);
  const [selectedDetailIndex, setSelectedDetailIndex] = useState<number>(-1);
  const [activeVariants, setActiveVariant] = useState<ActiveVariant[]>(activeVariantsSelector);
  const bookingSummary = useSelector(getBookingSummary);
  const [bookingSummaryCakes, setBookingSummaryCakes] = useState<any>(
    bookingSummary.cake || []
  );
  useEffect(() => {
    dispatch(setActiveVariants(activeVariants));
    setBookingSummaryCakes(activeVariants);
    store.dispatch(setSubtotalAmount());
  }, [activeVariants]);

  const handleDetailSelect = (index: number) => {
    // Get the selected detail
    const selectedDetail = cake.details[index];

    // Create the new ActiveVariant object
    const newVariant: ActiveVariant = {
      name: cake.name,
      price: selectedDetail.price,
      quantity: selectedDetail.quantity,
      unit: selectedDetail.unit,
    };

    // Check if the variant already exists
    const variantExists = activeVariants.some(variant => variant.name === newVariant.name);
    console.log(variantExists);

    if (!variantExists) {
        console.log(activeVariants);
      // Update activeVariants state with the new variant
      setActiveVariant([...activeVariants, newVariant]);
      setBookingSummaryCakes(activeVariants);
    }

    // Set the selected detail index
    setSelectedDetailIndex(index);
  };
  useEffect(() => {
    console.log(bookingSummaryCakes);
    dispatch(setBookingSummaryCake(bookingSummaryCakes));
    console.log(bookingSummary);
    store.dispatch(setSubtotalAmount());
  }, [bookingSummaryCakes]);

  const handleNext = () => {
    navigate("choose_decoration");
  };

  return (
      <S.ModalOverlay>
        <S.ModalContent>
          <S.CloseButton onClick={onClose}>
            <IoClose size={24} />
          </S.CloseButton>
          {/* <S.MenuItems>Cake</S.MenuItems>
          <S.Heading>Customize as per your taste</S.Heading>
          <S.GreyLine /> */}
          <S.FoodName>Customize as per requirement</S.FoodName>
          <S.FoodOptions>
            {cake?.details?.map((detail, index) => (
              <S.OptionRow key={index}>
                <S.OptionText>{detail.quantity}&nbsp;{detail.unit} </S.OptionText>
                <S.PriceButtonContainer>
                  <S.PriceText>&#8377; {detail.price}</S.PriceText>
                  {selectedDetailIndex === index ? (
                    <IoRadioButtonOn
                      size={20}
                      color="black"
                      style={{marginTop:"5px"}}
                      onClick={() => handleDetailSelect(index)} // Handle detail selection
                    />
                  ) : (
                    <IoRadioButtonOff
                      size={20}
                      color="black"
                      style ={{marginTop:"5px"}}
                      onClick={() => handleDetailSelect(index)} // Handle detail selection
                    />
                  )}
                </S.PriceButtonContainer>
              </S.OptionRow>
            ))}
          </S.FoodOptions>
          <S.BookNowBtn
            onClick={handleNext}
            type="submit"
          >
            Next step
          </S.BookNowBtn>
        </S.ModalContent>
      </S.ModalOverlay>
  );
};

export default CustomizationModal;
