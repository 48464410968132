import { serviceAvailableAtCitiesSelector,locationCodeMapSelector } from "../../../../../../redux/selectors/webConfigSelector";
import { useSelector,useDispatch,shallowEqual } from "react-redux";
import { useEffect, useState} from "react";
import { fetchWebConfig } from "../../../../../../apis/ConfigAPI";
import { setWebConfig } from "../../../../../../redux/actions/webConfigActions";
import {PROD, VERSION} from "../../../../../../endPointsConfig";
import { useNavigate, useLocation } from 'react-router-dom';
import {
    setTheaterInfo,
    setSelectedTheaterDate,
    setSelectedCity,
    setSelectedLocation,
    setLocationsCityWise
  } from "../../../../../../redux/actions/bookingActions";
import {getCityBookingsCount} from "../../../../../../redux/selectors/webConfigSelector";
import { store } from "../../../../../../store";
import { City } from "../../../../../Home/screens/HeroSectionWidget/SearchTheatresSection/SelectDateCity/SelectDateCity";
import * as S from "./CityPage.style";
import {ReactComponent as HistoricPicture} from "../../../../../../assets/images/Theaters/india-historic-places-skyline.svg";
import {ReactComponent as ArrowCityMove} from "../../../../../../assets/images/Theaters/ArrowMoveCity.svg";
import {ReactComponent as ArrowCityMoveRight} from '../../../../../../assets/images/Theaters/ArrowMoveCityRight.svg';
import {ReactComponent as HistoricPictureSmall} from "../../../../../../assets/images/Theaters/skyline_small.svg";
import {ReactComponent as ArrowMoveCitySmall} from "../../../../../../assets/images/Theaters/ArrowMoveCitySmall.svg";
import {ReactComponent as ArrowMoveCityRightSmall} from "../../../../../../assets/images/Theaters/ArrowMoveCityRightSmall.svg";
import {ReactComponent as ArrowRight} from "../../../../../../assets/images/Theaters/Arrow Right.svg";
import Footer from "../../../../../Home/screens/Footer/Footer";
import Header from "../../../../../Home/screens/Header/Header";
import Banner from "../../../../../Home/screens/Banner/Banner";
import { Bangalore, Hyderabad } from "../../../../../../seoContent";

interface CityPageProps {
  city: string;
}
const celebrationSpaces = {
  Bangalore:14,
  Hyderabad: 12,
  Delhi:20
}
const googleReviews = {
  Bangalore:"13000+",
  Hyderabad: "10000+",
  Delhi:"10000+"
}
 
const CityPage : React.FC<CityPageProps> = ({ city }) =>{
    const cityBookingsCount = useSelector(getCityBookingsCount) || {};
    const navigate = useNavigate();
    const currentPath = useLocation().pathname;
    const locationCodeMap = useSelector(locationCodeMapSelector) || {}
    const dispatch = useDispatch();
    const citiesData = useSelector(serviceAvailableAtCitiesSelector);
    const cityName = city.charAt(0).toUpperCase() + city.slice(1).toLowerCase();
    const [filteredCities, setFilteredCities] = useState<string[]>([]);
    console.log(citiesData);
    const cityDetails = {
      Bangalore: {
        title: 'Why you should book The Binge Town Private Theatres in Bengaluru',
        paragraphs: [
          "The Binge Town is India's first and largest chain of private theatre celebration spaces with five branches and 14 private celebration venues in Bengaluru. All our celebration venues are equipped with private theatre setup to give your surprise experience a cinematic touch and to make it more memorable.",
          "Our branches are located in Koramangala, Rajaji Nagar, Banashankari, Whitefield and Nagavara. We have luxurious and unique decoration themes in all our theatres ranging from romantic decoration for couples to grand decoration for groups. In addition to decoration, we also provide all other services like Cakes, Gifts, Photoshoot, Dry ice fog entry and much more! ",
          "With more than 10k plus Google reviews in our Bangalore branches and 4.9 star rating, our customers have testified for the experience we deliver in each and every celebration."
        ],
      },
      Hyderabad: {
        title: 'Why you should book The Binge Town Private Theatres in Hyderabad',
        paragraphs: [
          "The Binge Town is India's first and largest chain of private theatre celebration spaces with four branches and 12 private celebration venues in Hyderabad. All our celebration venues are equipped with private theatre setup to give your surprise experience a cinematic touch and to make it more memorable.",
          "Our branches are located in Jubilee Hills, Himayatnagar, Miyapur and Suchitra. We have luxurious and unique decoration themes in all our theatres ranging from romantic decoration for couples to grand decoration for groups. In addition to decoration, we also provide all other services like Cakes, Gifts, Photoshoot, Dry ice fog entry and much more! ",
          "With more than 10k plus Google reviews in our Hyderabad branches and 4.9 star rating, our customers have testified for the experience we deliver in each and every celebration."
        ],
      },
      Delhi: {
        title: 'Why you should book The Binge Town Private Theatres in Delhi',
        paragraphs: [
          "The Binge Town is India's first and largest chain of private theatre celebration spaces with four branches and 20 private celebration venues in Delhi. All our celebration venues are equipped with private theatre setup to give your surprise experience a cinematic touch and to make it more memorable.",
          "Our branches are located in Saket, Karol Bagh, Laxmi Nagar and Janakpuri. We have luxurious and unique decoration themes in all our theatres ranging from romantic decoration for couples to grand decoration for groups. In addition to decoration, we also provide all other services like Cakes, Gifts, Photoshoot, Dry ice fog entry and much more! ",
          "With more than 10k plus Google reviews in our Delhi branches and 4.9 star rating, our customers have testified for the experience we deliver in each and every celebration.",
        ],
      },
    };
    useEffect(() => {
        if (!Array.isArray(citiesData)|| (locationCodeMap && Object.keys(locationCodeMap).length === 0)) {
          fetchWebConfig()
            .then((response) => {
              dispatch(setWebConfig(response));
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }, []);
      const getLocationsByCity = (cityName: string) => {
        if (!Array.isArray(citiesData)) {
            console.error('citiesData is not an array or is undefined:', citiesData);
            return [];
        }
    
        const city = citiesData.find((city: any) => city.cityName === cityName);
        return city ? city.locations : [];
    };
    const locations = getLocationsByCity(cityName);
    const fetchLocationInfo = (locationCode: any) => {
      const sessionId = localStorage.getItem("tbt_njkwe_sessionId") || "";
      fetch(`${PROD}/webConfigv2?location=${locationCode}&newWeb=${VERSION}`,
            {
              method: "GET",
              headers: {
                  "sessionId": sessionId  // Fallback value ensures this is always a string
              }
          }
          )
          .then((response) => {
              return response.json();
          })
          .then((response) => {
              dispatch(setTheaterInfo(response));
          })
          .catch((error: any) => {
              console.log(error);
          });
    }
    useEffect(() => {
      // Filter cities to exclude the current city and select the first two remaining cities
      if (Array.isArray(citiesData) && citiesData.length > 0) {
        const filtered = citiesData
            .filter((c: any) => c.cityName !== cityName)
            .slice(0, 2)
            .map((c: any) => c.cityName);

        setFilteredCities(filtered);
    }
      let location: string | undefined;
      let fullLocObj: any | undefined;
  
      // Find the city object based on the given cityName
      let cityData;
      if(Array.isArray(citiesData)){
        cityData = citiesData.find((cityObj: City) => cityObj.cityName === cityName);
      }
  
      // If the city is found and it has locations, set the location and full location object
      if (cityData && cityData.locations?.length > 0) {
          location = cityData.locations[0]?.locationName; // Use the first location
          fullLocObj = cityData.locations[0];
      }
  
      // Calculate the selected date (next day)
      const today = new Date();
      const selectedDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
  
      // Dispatch actions to update state
      if (selectedDate) {
          dispatch(
              setSelectedTheaterDate({
                  year: selectedDate.getFullYear(),
                  month: selectedDate.getMonth(),
                  day: selectedDate.getDate(),
                  fullDate: selectedDate,
              })
          );
      }
  
      if (cityName) {
          store.dispatch(setSelectedCity(null, cityName));
      }
  
      if (fullLocObj) {
          dispatch(setSelectedLocation(fullLocObj));
      }
  
      store.dispatch(setLocationsCityWise());
  
      // Fetch location information if location is defined and mapped
      if (location && locationCodeMap[location]) {
          fetchLocationInfo(locationCodeMap[location]);
      }
  }, [cityName, citiesData, dispatch]); // Add dependencies here to avoid missing dependencies warnings
  
    const handleClick = (city:any) => {
      // Convert city name to lowercase and redirect
      const cityName = city.toLowerCase();
      navigate(`/private-theatres-in-${cityName}`);
    };

    return(
        <>
        <Header/>
        <Banner/>
        <S.PictureContainer>
          <S.CityPicture>
            <S.BingeTownText>The Binge Town</S.BingeTownText>
          {cityName && <S.CityText>{cityName}</S.CityText>}
            {window.innerWidth> 767 &&<HistoricPicture/>}
            {window.innerWidth<768 && <HistoricPictureSmall style={{width:"100%"}}/>}
          </S.CityPicture>
        </S.PictureContainer>
        {window.innerWidth<768 && <S.StatisticsBox>
            <S.InfoContainer>
              <S.StatsNumber>
              {celebrationSpaces[cityName as keyof typeof celebrationSpaces]}
              </S.StatsNumber>
              <S.StatsSpecification>
                Celebration Spaces
              </S.StatsSpecification>
            </S.InfoContainer>
            <S.HorizontalLine/>
            <S.InfoContainer>
              <S.StatsNumber>
              {cityBookingsCount[cityName]}
              </S.StatsNumber>
              <S.StatsSpecification>
              Bookings Completed
              </S.StatsSpecification>
            </S.InfoContainer>
            <S.HorizontalLine/>
            <S.InfoContainer>
              <S.StatsNumber>
              {googleReviews[cityName as keyof typeof googleReviews]}
              </S.StatsNumber>
              <S.StatsSpecification>
              Google Reviews
              </S.StatsSpecification>
            </S.InfoContainer>
          </S.StatisticsBox>}
        <S.CityLocationContainer>
          <S.VenueHeading>
            Our Venues In {cityName}
          </S.VenueHeading>
          <S.LocationOptionsContainer>
          {locations.map((location:any, index:any) => (
          <S.LocationOption
          key={index} // Unique key for each location
          onClick={() => {
              let pathSplits = currentPath.split('-');
              if(pathSplits.length < 4) {
                  navigate(
                      `/private-theatres-in-${city}/private-celebration-venue-in-${location?.locationName.toLowerCase()}`
                  )
              } else {
                  navigate(
                      `${currentPath}/private-celebration-venue-in-${location?.locationName.toLowerCase()}`
                  )
              }
          }
          }
          style={{ cursor: "pointer" }} // Optional: Show a pointer cursor
          >
              
              <img
                key={index} // Ensure each child has a unique key
                src={location?.locationImageURL} // Correct property name based on your data
                alt="theaterImage"
                title={location?.locationName || "theaterImage"} // Optional: Use locationName for better alt/title
                style={{
                  width:"100%",
                  height:"100%",
                  objectFit: "cover",
                  overflow: "hidden",
                  borderRadius: "12px",
                }}
              />
              <S.LocationTextContainer>
                <S.LocationBox>
                  <S.LocationText>
                    {location?.locationName}
                  </S.LocationText>
                  <S.TheatreText>
                  {location?.code?.length} Theatres
                </S.TheatreText>
              </S.LocationBox>

              </S.LocationTextContainer>
            
          </S.LocationOption>))}

          </S.LocationOptionsContainer>
        </S.CityLocationContainer>
        <S.InformationContainer>
          <S.TitleTextContainer>
            <S.FirstTitleTextContainer>
              <S.TitleText>
              {cityDetails[cityName as keyof typeof cityDetails]?.title}
              </S.TitleText>
              {cityDetails[cityName as keyof typeof cityDetails]?.paragraphs.map((para, ind) => (
                <S.InformationText key={ind}>
                  {para}
                </S.InformationText>
              ))}
            </S.FirstTitleTextContainer>
          </S.TitleTextContainer>
          {window.innerWidth>767 &&<S.StatisticsBox>
            <S.InfoContainer>
              <S.StatsNumber>
              {celebrationSpaces[cityName as keyof typeof celebrationSpaces]}
              </S.StatsNumber>
              <S.StatsSpecification>
              Celebration Spaces
              </S.StatsSpecification>
            </S.InfoContainer>
            <S.HorizontalLine/>
            <S.InfoContainer>
              <S.StatsNumber>
              {cityBookingsCount && cityBookingsCount[cityName]}
              </S.StatsNumber>
              <S.StatsSpecification>
              Bookings Completed
              </S.StatsSpecification>
            </S.InfoContainer>
            <S.HorizontalLine/>
            <S.InfoContainer>
              <S.StatsNumber>
              {googleReviews[cityName as keyof typeof googleReviews]}
              </S.StatsNumber>
              <S.StatsSpecification>
              Google Reviews
              </S.StatsSpecification>
            </S.InfoContainer>
          </S.StatisticsBox>}
        </S.InformationContainer>
        
        <S.OtherCitiesBar>
          <S.OtherCityMoveBox onClick={() => handleClick(filteredCities[0])}>
          {window.innerWidth> 767 &&<ArrowCityMove/>}
          {window.innerWidth<768 && <ArrowMoveCitySmall/>}
            <S.OtherCityText>{ filteredCities[0]}</S.OtherCityText>
          </S.OtherCityMoveBox>
          <S.OtherCityMoveBox onClick={() => handleClick(filteredCities[1])}>
            <S.OtherCityText>{filteredCities[1]}</S.OtherCityText>
            {window.innerWidth> 767 &&<ArrowCityMoveRight/>}
            {window.innerWidth<768 && <ArrowMoveCityRightSmall/>}
          </S.OtherCityMoveBox>
        </S.OtherCitiesBar>
        <S.JoinWaitlistContainer>
          <S.HeadingWaitlist>
          Can’t find a slot? Join our waitlist
          </S.HeadingWaitlist>
          <S.WaitlistText>
          Fill in your slot requirements and we will send you an alert on WhatsApp as soon as a slot becomes free due to cancellation.
          </S.WaitlistText>
          <S.JoinWaitListButton onClick={() =>
            navigate(`/join_waitlist`)
          }>
            <S.JoinWaitListText>
              Join Waitlist
            </S.JoinWaitListText>
            <ArrowRight/>
          </S.JoinWaitListButton>
        </S.JoinWaitlistContainer>
        <Footer/>
        </>
    );
}
export default CityPage;