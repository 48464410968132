import * as S from "./EditLocationModal.style";
import Select from "react-select";
import { FaSearch, FaAngleDown } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { PiCalendar } from "react-icons/pi";
import {useState,useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { store } from "../../../../../../../store";
import {PROD, VERSION} from "../../../../../../../endPointsConfig";
import Calendar from 'react-calendar';
import { serviceAvailableAtCitiesSelector } from "../../../../../../../redux/selectors/webConfigSelector";
import { resetUpdateBookingDetail } from "../../../../../../../redux/actions/blogsActions";
import { setSelectedTheaterDate,
    setSelectedCity, 
    setSelectedLocation, 
    setLocationsCityWise,
    setTheaterTimeSlots, 
    setTheaterInfo,
    resetActiveVariants,
    resetActiveDecorations,
    resetActiveGifts,
    resetActiveServices,
    resetBookingDetails,
    resetBookingSummaryAddons,
    resetExtraNumberOfPeople,
    resetSelectedOccasion,
    setCouponAppliedSuccessfully } from "../../../../../../../redux/actions/bookingActions";
import { getSelectedCity, getSelectedTheaterDate,getSelectedLocation } from "../../../../../../../redux/selectors/bookingSelector";
import { set } from "react-ga";
interface Location {
    locationName: string;
    locationImageURL: string;
    code: string[]; // Assuming code is an array of strings
}
export interface CityData {
    cityName: string;
    locations: Location[];
}
interface EditLocationModalProps {
    isOpenModal: boolean;
    setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}
interface LocationOption {
    value: string;
    label: string;
    locationName: string;
    locationImageURL: string;
    code: string[]; // Assuming 'code' is an array of strings
}

const EditLocationModal : React.FC<EditLocationModalProps> = ({ isOpenModal, setIsOpenModal }) =>{
    const dispatch = useDispatch();
    const citiesData = useSelector(serviceAvailableAtCitiesSelector);
    const date = useSelector(getSelectedTheaterDate);
    const location = useSelector(getSelectedLocation);
    const [showCalendar, setShowCalendar] = useState<boolean>(false);
    const [locations, setLocations] = useState<Location[]>([]);
    const [selectedDate, setSelectedDate] = useState<Date>(date.fullDate);
    const [selectedViewCity, setSelectedViewCity] = useState<string>("Bangalore");
    const selectedCity =  useSelector(getSelectedCity);
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState<LocationOption>({
        value: location.locationName,
        label: location.locationName,
        locationName: location.locationName,
        locationImageURL: location.locationImageURL,
        code: location.code,
    });
    useEffect(() => {
        if (citiesData.length && selectedCity) {
            const cityData = citiesData.find((city:CityData) => city.cityName === selectedCity);
            if (cityData) {
                setLocations(cityData.locations);
            } else {
                setLocations([]);
            }
        }
    }, [citiesData, selectedCity]);
    const locationOptions = locations.map((location, idx) => ({
        value: location.locationName,
        locationName: location.locationName,
        code: location.code,
        label: location.locationName,
        locationImageURL: location.locationImageURL,
        key: idx,
    }));
    const fetchCountColor = async (locationPrefix: any, day: any, month: any, year: any) => {

        try {
            const sessionId = localStorage.getItem("tbt_njkwe_sessionId") || "";
            const response = await fetch(
                `${PROD}/getSlots?getDate=false&date=${month}-${day}-${year}&location=${locationPrefix}`,
                {
                    method: "GET",
                    headers: {
                        "sessionId": sessionId  // Fallback value ensures this is always a string
                    }
                }
            );
            const resp = await response.json();
            let count = resp.count;
            if (count > 0) {
                handleTheaterProceed();
            } else {
                alert("No slot available for the selected date and location");
            }// Update count state
        } catch (err) {
            console.log("Error fetching slot count", err);
        }
    };
    const handleTheaterProceed = () => {
        const date = new Date(selectedDate);
        navigate(`/private-theatres-in-${selectedCity.toLowerCase()}/private-celebration-venue-in-${selectedOption?.value.toLowerCase()}/date-selection?date=${date.getFullYear()}_${date.getMonth() + 1}_${date.getDate()}`);
    };
    const fetchTheaterSlots = (theaters: any, day: any, month: any, year: any) => {

        const date = `${year}${month}${day}`;
        const theaterTimeSlots: any = {};

        // Function to fetch slots for a specific theater code
        const fetchSlotsForTheater = async (theaterCode: string) => {
            try {
                const sessionId = localStorage.getItem("tbt_njkwe_sessionId") || "";
                const response = await fetch(`${PROD}/getSlots?code=${theaterCode}_${date}&getDate=false`,
                    {
                        method: "GET",
                        headers: {
                            "sessionId": sessionId  // Fallback value ensures this is always a string
                        }
                    }
                );
                const data = await response.json();
                return { theaterCode, slots: data.slots };
            } catch (error) {
                console.error(`Failed to fetch slots for ${theaterCode}`, error);
                return { theaterCode, slots: [0, 1, 2, 3] }; // Handle errors gracefully
            }
        };

        // Create an array of fetch promises
        const fetchPromises = theaters.map((theaterCode: string) => fetchSlotsForTheater(theaterCode));

        // Wait for all fetches to complete
        Promise.all(fetchPromises).then(results => {
            results.forEach(({ theaterCode, slots }: any) => {
                theaterTimeSlots[theaterCode] = slots;
            });
            dispatch(setTheaterTimeSlots(theaterTimeSlots));
        });
    }
    console.log(selectedOption);
    const handleSearchClick = () => {
        if (!selectedViewCity || !selectedOption) {
            alert("Please select a location before proceeding.");
            return;
        }
        if (selectedOption) {
            const date = new Date(selectedDate);
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            dispatch(
                setSelectedTheaterDate({
                    year: year,
                    month: month,
                    day: day,
                    fullDate: selectedDate,
                })
            );
            dispatch(setSelectedLocation({
                locationImageURL: selectedOption.locationImageURL,
                locationName: selectedOption.locationName,
                code: selectedOption.code
            }));
            store.dispatch(setLocationsCityWise());
            const locationPrefix = selectedOption.code[0].split('_')[0];
            fetchLocationInfo(locationPrefix);
            fetchTheaterSlots(selectedOption.code, day, month, year)
            fetchCountColor(locationPrefix, day, month, year);
            setIsOpenModal(false);
        }
    };
    const fetchLocationInfo = (locationCode: any) => {
        const sessionId = localStorage.getItem("tbt_njkwe_sessionId") || "";
        fetch(`${PROD}/webConfigv2?location=${locationCode}&newWeb=${VERSION}`,
                {
                    method: "GET",
                    headers: {
                        "sessionId": sessionId  // Fallback value ensures this is always a string
                    }
                }
            )
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                dispatch(setTheaterInfo(response));
            })
            .catch((error: any) => {
                console.log(error);
            });
    }
    useEffect(() => {
        dispatch(resetActiveVariants());
        dispatch(resetBookingSummaryAddons());
        dispatch(resetActiveDecorations());
        dispatch(resetActiveGifts());
        dispatch(resetActiveServices());
        dispatch(resetExtraNumberOfPeople());
        dispatch(resetBookingDetails());
        dispatch(resetSelectedOccasion());
        dispatch(setCouponAppliedSuccessfully(false));
        dispatch(resetUpdateBookingDetail());
    }, []);

    return (
        <>
        const dispatch = useDispatch();
        <S.ModalOverlay>
            <S.ModalContent>
            <S.CloseButton onClick={() => setIsOpenModal(false)}>
            <IoClose size={24} color="#808080" />
          </S.CloseButton>
            <S.ChooseRow>
                <Select
                    value={selectedOption}
                    onChange={(option) => setSelectedOption(option as LocationOption)} // Set both value and code
                    onMenuOpen={() => setShowCalendar(false)} // Close the calendar when the dropdown is opened
                    options={locationOptions}
                    isSearchable={false}
                    components={{
                        DropdownIndicator: () => (
                            <FaAngleDown style={{ color: 'gray' }} />
                        ),
                    }}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "10px",
                            padding: "5px"
                        }),
                        singleValue: (provided) => ({
                            ...provided,
                            fontSize: '12px',
                            color: 'gray',
                            fontFamily: 'DM Sans, sans-serif',
                            zIndex: '1000',
                        }),
                        placeholder: (provided) => ({
                            ...provided,
                            fontSize: '12px',
                            color: 'gray',
                            fontFamily: 'DM Sans, sans-serif',
                        }),
                        menu: (provided) => ({
                            ...provided,
                            fontSize: '12px',
                            fontFamily: 'DM Sans, sans-serif',
                        }),
                        menuPortal: (provided) => ({
                            ...provided,
                            zIndex: 1200, // Ensure it appears above other elements
                        }),
                    }}
                    menuPortalTarget={document.body} // Render the dropdown into the body
                    menuPosition="fixed" // Fix the position for proper display outside container
                    menuPlacement="auto" // Adjust the placement of the dropdown dynamically
                />
                <S.RowDate onClick={() => setShowCalendar(!showCalendar)}>
                    <S.SelectLocationDate>
                        <S.SelectDateContainer>
                            <S.DateContainerText>
                                <S.DateText>
                                    DATE
                                </S.DateText>
                                <S.ChooseDateText>
                                {selectedDate.toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" })}
                                </S.ChooseDateText>
                            </S.DateContainerText>
                            <S.CalendarContainer>
                                <PiCalendar style={{ color: 'gray', top: '25%', fontSize: '24px' }} />

                            </S.CalendarContainer>
                        </S.SelectDateContainer>
                    </S.SelectLocationDate>
                    {showCalendar && (
                <S.CalendarWrapper onClick={(e) => e.stopPropagation()}>
                    <Calendar onChange={(value) => {
                        if (value instanceof Date) {
                            setSelectedDate(value);
                        } else if (Array.isArray(value) && value[0] instanceof Date) {
                            setSelectedDate(value[0]);
                        }
                        setShowCalendar(false);
                    }} value={selectedDate} minDate={new Date()} />
                </S.CalendarWrapper>
            )}
            </S.RowDate>
                <S.SearchSmallScreen onClick={handleSearchClick}>
                    <S.SearchText>Update</S.SearchText>
                </S.SearchSmallScreen>
                </S.ChooseRow>
            </S.ModalContent>
        </S.ModalOverlay>
        </>
    );

};

export default EditLocationModal;