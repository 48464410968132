import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

import * as S from "./PaymentPage.style";
import {postOrder} from "../../../../apis/ConfigAPI";

const PaymentPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const order_id = queryParams.get('order_id');  // Example query param

    const [allDetails, setAllDetails] = useState<any>({}) || {};

    useEffect(() => {
        const fetchData = async () => {
            console.log("Details are ",allDetails);
            if(!allDetails || Object.keys(allDetails).length == 0){
                let details = await postOrder(order_id)
                if(!details){
                    alert(`Error while getting order details`);
                    return;
                }
                setAllDetails(details);
                console.log("Details are ",details);
            }
        }
       fetchData();
    }, []);

    if(!allDetails){
        return (<div>Loading...</div>);
    }

    return (
        <S.BookingSummaryContainer>
            <S.Heading>Booking summary</S.Heading>
            <S.PriceBreakup>
                <S.DetailPriceBreakup>
                    <S.Section>
                        <S.SectionTxt>
                            {allDetails.theatre} ({allDetails?.numOfPeople})
                        </S.SectionTxt>
                        <S.SectionPrice>&#8377; {allDetails?.totalPrice}</S.SectionPrice>
                    </S.Section>
                    {allDetails?.decorType !== "None" ? (
                        <S.Section>
                            <S.SectionTxt>Decoration</S.SectionTxt>
                            <S.SectionPrice>&#8377; {allDetails?.decorPrice}</S.SectionPrice>
                        </S.Section>
                    ) : null}

                    {allDetails.cakeAry &&
                        allDetails.cakeAry.map((cake: { name: String; price: number }) => {
                            return (
                                <S.Section>
                                    <S.SectionTxt>{cake?.name} </S.SectionTxt>
                                    <S.SectionCount> X 1</S.SectionCount>
                                    <S.SectionPrice>&#8377; {cake.price}</S.SectionPrice>
                                </S.Section>
                            );
                        })}

                    {allDetails.addOnAry &&
                        allDetails.addOnAry.map((addon: { name: String; price: number, quantity:String }) => {
                            if(addon.name.includes("Fog Entry")){
                              return (
                                <S.Section>
                                  <S.SectionTxt>{addon.name} </S.SectionTxt>
                                  <S.SectionCount> X {addon.quantity}</S.SectionCount>
                                  <S.SectionPrice>&#8377; {addon.price}</S.SectionPrice>
                                </S.Section>
                              );
                            }
                            return (
                                <S.Section>
                                    <S.SectionTxt>{addon.name} </S.SectionTxt>
                                    <S.SectionCount> X 1</S.SectionCount>
                                    <S.SectionPrice>&#8377; {addon.price}</S.SectionPrice>
                                </S.Section>
                            );
                        })}
                </S.DetailPriceBreakup>
                <div style={{ borderBottom: "1px solid #C8C8C8" }}></div>

                <S.SubtotalContainer>
                    <S.Section>
                        <S.SectionTxt>Subtotal</S.SectionTxt>
                        <S.SectionPrice>&#8377; {allDetails.totalPrice}</S.SectionPrice>
                    </S.Section>
                </S.SubtotalContainer>
                <div style={{ borderBottom: "1px solid #C8C8C8" }}></div>

                <S.AdvancePaymentContainer>
                    <S.Section>
                        <S.AmountPayableTxt>Advance amount payable</S.AmountPayableTxt>
                        <S.SectionPrice>&#8377; {allDetails.advancePaid}</S.SectionPrice>
                    </S.Section>
                </S.AdvancePaymentContainer>
                <div style={{ borderBottom: "1px solid #C8C8C8" }}></div>

                <S.BalanceAmountContainer>
                    <S.Section>
                        <S.BalanceAmount>
                            <S.SectionTxt>Balance amount</S.SectionTxt>
                            <S.SectionSubtxt>(Payable at the venue)</S.SectionSubtxt>
                        </S.BalanceAmount>

                        <S.SectionPrice>
                            &#8377;{" "}
                            allDetails.totalPrice - allDetails.advancePaid
                        </S.SectionPrice>
                    </S.Section>
                </S.BalanceAmountContainer>
            </S.PriceBreakup>

            <S.FooterView>
                    <>
                            <S.ButtonView
                                type="button"
                                //onClick={handleNext}
                            >
                                Pay &#8377; {allDetails.advancePaid}
                            </S.ButtonView>
                    </>
            </S.FooterView>
        </S.BookingSummaryContainer>
    );
};

export default PaymentPage;
