import styled from "styled-components";

export const PictureContainer = styled.div`
    display: flex;
    width: 100%;
    padding: 4.5rem;
    flex-direction: column;
    align-items: flex-start;
    @media only screen and (max-width: 768px) {
        padding: 1.25rem;
    }
`
export const CityPicture = styled.div`
    display: flex;
    height: 100%;
    padding-top:1.25rem;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    border-radius: var(--2xl, 1.5rem);
    overflow: hidden;
    background: linear-gradient(90deg, rgba(52, 38, 92, 0.10) 0%, rgba(255, 144, 0, 0.10) 100%);   
`
export const BingeTownText = styled.div`

align-self: stretch;
    color: var(--Primary-Violet-violet-6, #34265C);
    text-align: center;

    /* Headings/H2 */
    font-family: "DM Sans", sans-serif;
    font-size: var(--Font-Size-Heading-Heading-2, 20px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--Line-Height-Heading-5xl, 28px); /* 144.444% */

`
export const CityText = styled.div`
    align-self: stretch;
    color: var(--Primary-Violet-violet-6, #34265C);
    text-align: center;

    /* Headings/H2 */
    font-family: "DM Sans", sans-serif;
    font-size: var(--Font-Size-Heading-Heading-2, 36px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--Line-Height-Heading-5xl, 52px); /* 144.444% */
`
export const InformationContainer = styled.div`
    display: flex;
    width: 100%;
    padding: 0px 4.5rem;
    align-items: flex-start;
    gap: 10%;
    margin-top:6.5rem;
    align-items: center;
    @media only screen and (max-width: 768px) {
        margin-top: 4rem;
        gap:40px;
        flex-direction: column;
        padding: 0px 1.25rem;
    }
`
export const TitleTextContainer = styled.div`
    display: flex;
    width: 60%;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    flex-shrink: 0;
    @media only screen and (max-width: 768px) {
        width:100%;
    }

`
export const FirstTitleTextContainer = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    align-self: stretch;
`
export const TitleText = styled.div`
    color: var(--Neutral-Grey-grey-13, #101010);

    /* Paragraph/MD/P-MD-SemiBold */
    font-family: "DM Sans", sans-serif;
    font-size: var(--Font-Size-Paragraph-p-md-base, 16px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--Line-Height-Paragraph-md-base, 24px); /* 150% */
`
export const InformationText = styled.div`
    align-self: stretch;
    color: var(--Neutral-Grey-grey-10, #343434);

    /* Paragraph/SM/P-SM */
    font-family: "DM Sans", sans-serif;
    font-size: var(--Font-Size-Paragraph-p-sm, 14px);
    font-style: normal;
    font-weight: 400;
    line-height: var(--Line-Height-Paragraph-sm, 20px); /* 142.857% */
`
export const StatisticsBox = styled.div`
    display: flex;
    width: 30%;
    padding: 2.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.5rem;
    flex-shrink: 0;
    border-radius: var(--2xl, 1.5rem);
    background: var(--Secondary-Green-Green-8, #22523A);   
    @media only screen and (max-width: 768px) {
        width: 90%;
        margin:5%;
        gap: 1.5rem;
        padding: 1.5rem;
    } 
`
export const InfoContainer = styled.div`
    display: flex;
    padding: 0px 1.5rem;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    align-self: stretch;
    border-radius: var(--xl, 1.25rem);
`
export const StatsNumber = styled.div`
    color: var(--Neutral-Grey-grey-1, #FFF);

    /* Headings/H4 */
    font-family: "DM Sans", sans-serif;
    font-size: var(--Font-Size-Heading-Heading-4, 28px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--Line-Height-Heading-3xl, 40px); /* 142.857% */
`
export const StatsSpecification = styled.div`
    text-align: center;
    color: var(--Neutral-Grey-grey-1, #FFF);

    /* Paragraph/SM/P-SM-SemiBold */
    font-family: "DM Sans", sans-serif;
    font-size: var(--Font-Size-Paragraph-p-sm, 14px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--Line-Height-Paragraph-sm, 20px); /* 142.857% */
`
export const HorizontalLine = styled.div`

    height: 1px;
    align-self: stretch;
    border-radius: var(--infinite, 9999px);
    border: 1px solid var(--Neutral-Grey-white200, rgba(255, 255, 255, 0.20));
`
export const CityLocationContainer = styled.div`
    display: flex;
    width: 100%;
    padding: 0px 4.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    @media only screen and (max-width: 768px) {
        padding: 0px 1.25rem;
        flex-direction: column;
        margin-top: 4rem;
    }
`
export const VenueHeading = styled.div`
    align-self: stretch;
    color: var(--Neutral-Grey-grey-13, #101010);

    /* Headings/H5 */
    font-family: "DM Sans", sans-serif;
    font-size: var(--Font-Size-Heading-Heading-5, 24px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--Line-Height-Heading-2xl, 36px); /* 150% */
`
export const LocationOptionsContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-start;
    align-content: flex-start;
    gap: 0.75rem;
    flex-wrap: wrap;
`
export const LocationOption = styled.div`
    height: 15rem;
    align-self: stretch;
    position: relative;
    @media only screen and (max-width: 768px) {
        width: 100%;
        height: auto;
    }
    
`
export const LocationTextContainer = styled.div`
    width: 100%;
    height: 12.5rem;
    position: absolute;
    bottom: 0px;
`
export const LocationBox = styled.div`
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    left: 1.25rem;
    bottom: 1.25rem;
`
export const LocationText = styled.div`
    color: var(--Neutral-Grey-grey-1, #FFF);

    /* Headings/H6 */
    font-family: "DM Sans", sans-serif;
    font-size: var(--Font-Size-Heading-Heading-6, 20px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--Line-Height-Heading-xl, 32px); /* 160% */
`
export const TheatreText = styled.div`
    color: var(--Neutral-Grey-grey-1, #FFF);

    /* Paragraph/MD/P-MD */
    font-family: "DM Sans", sans-serif;
    font-size: var(--Font-Size-Paragraph-p-md-base, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: var(--Line-Height-Paragraph-md-base, 24px); /* 150% */
`
export const OtherCitiesBar = styled.div`
    display: flex;
    width: 90%;
    padding: 2rem;
    justify-content: space-between;
    align-items: center;
    border-radius: var(--2xl, 1.5rem);
    background: var(--Secondary-Blue-blue-10, #093A54);
    margin: 6.5rem 5%;
    @media only screen and (max-width: 768px) {
        border-radius: var(--lg, 1rem);
        padding: 1.25rem 0.75rem;
        margin: 4rem 5%;
    }
`
export const OtherCityMoveBox = styled.div`
    display: flex;
    align-items: center;
    gap: 1.25rem;
    cursor: pointer;
    @media only screen and (max-width: 768px) {
        gap: 0.375rem;
    }
`
export const OtherCityText = styled.div`
    color: var(--Neutral-Grey-grey-1, #FFF);

    /* Paragraph/LG/P-LG-SemiBold */
    font-family: "DM Sans", sans-serif;
    font-size: var(--Font-Size-Paragraph-p-lg, 18px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--Line-Height-Paragraph-lg, 28px); /* 155.556% */
    @media only screen and (max-width: 768px) {
        font-size: var(--Font-Size-Paragraph-p-md-base, 16px);
        line-height: var(--Line-Height-Paragraph-md-base, 24px); /* 150% */

    }
`
export const JoinWaitlistContainer = styled.div`
    display: flex;
    padding: 3rem 15rem;
    flex-direction: column;
    align-items: center;
    gap: 1.25rem;
    align-self: stretch;
    border-radius: var(--2xl, 1.5rem);
    background: radial-gradient(224.52% 350.84% at 52.41% 49.61%, #FFF4E6 0%, #FFCF91 100%);
    margin: 5%;
    width:90%;
    @media only screen and (max-width: 768px) {
        padding: 2rem;
    }
`
export const HeadingWaitlist = styled.div`
    align-self: stretch;
    color: var(--Neutral-Grey-grey-13, #101010);
    text-align: center;

    /* Headings/H2 */
    font-family: "DM Sans", sans-serif;
    font-size: var(--Font-Size-Heading-Heading-2, 36px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--Line-Height-Heading-5xl, 52px); /* 144.444% */
    @media only screen and (max-width: 768px) {
        font-size: var(--Font-Size-Heading-Heading-3, 32px);
        line-height: var(--Line-Height-Heading-4xl, 44px); /* 137.5% */
    }
`
export const WaitlistText = styled.div`
    align-self: stretch;
    color: var(--Neutral-Grey-grey-10, #343434);
    text-align: center;

    /* Paragraph/SM/P-SM */
    font-family: "DM Sans", sans-serif;
    font-size: var(--Font-Size-Paragraph-p-sm, 14px);
    font-style: normal;
    font-weight: 400;
    line-height: var(--Line-Height-Paragraph-sm, 20px); /* 142.857% */
`
export const JoinWaitListButton = styled.div`
    display: flex;
    padding: 0.75rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    border-radius: var(--md-base, 12px);
    background: var(--Neutral-Grey-grey-13, #101010);
    cursor: pointer;
    @media only screen and (max-width: 768px) {
        align-self : stretch;
    }
`
export const JoinWaitListText = styled.div`
    color: var(--Neutral-Grey-grey-1, #FFF);
    text-align: center;

    /* Paragraph/XS/P-XS-Medium */
    font-family: "DM Sans", sans-serif;
    font-size: var(--Font-Size-Paragraph-p-xs, 12px);
    font-style: normal;
    font-weight: 500;
    line-height: var(--Line-Height-Paragraph-xs, 16px); /* 133.333% */
`