
import { FaSearch, FaAngleDown } from "react-icons/fa";
import { PiCalendar } from "react-icons/pi";
import { useState, useEffect, useRef } from "react";
import { serviceAvailableAtCitiesSelector } from "../../../../../../redux/selectors/webConfigSelector";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {PROD, VERSION} from "../../../../../../endPointsConfig";
import { useNavigate } from "react-router-dom";
import {
    getSelectedTheaterDate
} from "../../../../../../redux/selectors/bookingSelector";
import * as S from "./SelectDateCity.style";
import styled from "styled-components";
import {
    resetActiveVariants,
    resetActiveDecorations,
    resetActiveGifts,
    resetActiveServices,
    resetBookingDetails,
    resetBookingSummaryAddons,
    resetExtraNumberOfPeople,
    resetSelectedOccasion,
    setCouponAppliedSuccessfully,
    setLocationsCityWise,
    setSelectedCity,
    setSelectedLocation,
    setSelectedTheaterDate,
    setTheaterInfo,
    setTheaterTimeSlots
} from "../../../../../../redux/actions/bookingActions";
import { store } from "../../../../../../store";
import { resetUpdateBookingDetail } from "../../../../../../redux/actions/blogsActions";
import { ClassNames } from "@emotion/react";
import BookingDone from "../BookingDone/BookingDone";

export interface City {
    cityImageURL: string;
    cityName: string;
    locations: Location[];
}
interface Location {
    locationName: string;
    locationImageURL: string;
    code: string[]; // Assuming code is an array of strings
}
interface CityProps {
    isSelected: boolean;
}
const City = styled.div<CityProps>`
  width: 30%;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  font-family: "DM Sans", sans-serif;
  color: ${({ isSelected }) => (isSelected ? "transparent" : "black")};
  font-weight: ${({ isSelected }) => (isSelected ? "bold" : "normal")};
  padding-bottom: ${({ isSelected }) => (isSelected ? "10px" : "0px")};
  position: relative;
  display: inline-block;
  background: ${({ isSelected }) => isSelected ? "linear-gradient(36deg, #1A2A6C 8.48%, #B21F1F 55.8%, #FDBB2D 98.44%)" : "transparent"};
  background-clip: text;
  -webkit-background-clip: text; /* For Safari */

  /* After pseudo-element for selected city with gradient */
  ${({ isSelected }) =>
        isSelected &&
        `
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(36deg, #1A2A6C 8.48%, #B21F1F 55.8%, #FDBB2D 98.44%);
      display: inline-block;
    }
  `}
`;

const SelectDateCity = () => {
    const dispatch = useDispatch();
    const citiesData = useSelector(serviceAvailableAtCitiesSelector);
    const [selectedCityIndex, setSelectedCityIndex] = useState<number>(0);
    const [selectedViewCity, setSelectedViewCity] = useState<string>("Bangalore");
    const [locations, setLocations] = useState<Location[]>([]);
    const [selectedOption, setSelectedOption] = useState<any | null>(null);
    const [showCalendar, setShowCalendar] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const selectedTheatreDate = useSelector(getSelectedTheaterDate);
    const navigate = useNavigate();
    const [showDate, setShowDate] = useState<boolean>(false);
    useEffect(() => {
        if (selectedCityIndex !== -1) {
            const newLocations = citiesData[selectedCityIndex]?.locations || [];
            setLocations(newLocations);
        }
    }, [selectedCityIndex, citiesData]);

    // Handle city selection
    const handleCityClick = (index: number) => {
        setSelectedCityIndex(index);
        setSelectedOption(null); // Reset selected option when city changes
        setSelectedViewCity(citiesData[index]?.cityName);
    };

    const customPlaceholder = () => (
        <S.LocationContainer>
            <S.LocationText>LOCATION</S.LocationText>
            <S.ChooseLocationText >Choose a location</S.ChooseLocationText>
        </S.LocationContainer>
    );

    const locationOptions = locations.map((location, idx) => ({
        value: location.locationName,
        locationName: location.locationName,
        code: location.code,
        label: location.locationName,
        locationImageURL: location.locationImageURL,
        key: idx,
    }));

    useEffect(() => {
        dispatch(resetActiveVariants());
        dispatch(resetBookingSummaryAddons());
        dispatch(resetActiveDecorations());
        dispatch(resetActiveGifts());
        dispatch(resetActiveServices());
        dispatch(resetExtraNumberOfPeople());
        dispatch(resetBookingDetails());
        dispatch(resetSelectedOccasion());
        dispatch(setCouponAppliedSuccessfully(false));
        dispatch(resetUpdateBookingDetail());
    }, []);

    const fetchCountColor = async (locationPrefix: any, day: any, month: any, year: any) => {

        try {
            const sessionId = localStorage.getItem("tbt_njkwe_sessionId") || "";
            const response = await fetch(
                `${PROD}/getSlots?getDate=false&date=${month}-${day}-${year}&location=${locationPrefix}`,
                {
                    method: "GET",  // Optional, "GET" is the default method, but can be specified explicitly
                    headers: {
                        "sessionId": sessionId
                    }
                }
            );
            const resp = await response.json();
            let count = resp.count;
            if (count > 0) {
                handleTheaterProceed();
            } else {
                alert("No slot available for the selected date and location");
            }// Update count state
        } catch (err) {
            console.log("Error fetching slot count", err);
        }
    };

    const handleSearchClick = () => {
        if (!selectedViewCity || !selectedOption) {
            alert("Please select a location before proceeding.");
            return;
        }
        if (!showDate) {
            alert("Please select a date before proceeding.");
            return;
        }
        if (selectedOption) {
            const date = new Date(selectedDate);
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            dispatch(
                setSelectedTheaterDate({
                    year: year,
                    month: month,
                    day: day,
                    fullDate: selectedDate,
                })
            );
            store.dispatch(setSelectedCity(null, selectedViewCity));
            dispatch(setSelectedLocation({
                locationImageURL: selectedOption.locationImageURL,
                locationName: selectedOption.locationName,
                code: selectedOption.code
            }));
            store.dispatch(setLocationsCityWise());
            const locationPrefix = selectedOption.code[0].split('_')[0];
            fetchLocationInfo(locationPrefix);
            fetchTheaterSlots(selectedOption.code, day, month, year)
            fetchCountColor(locationPrefix, day, month, year);
        }
    };

    const fetchTheaterSlots = (theaters: any, day: any, month: any, year: any) => {

        const date = `${year}${month}${day}`;
        const theaterTimeSlots: any = {};

        // Function to fetch slots for a specific theater code
        const fetchSlotsForTheater = async (theaterCode: string) => {
            try {
                const sessionId = localStorage.getItem("tbt_njkwe_sessionId") || "";
                const response = await fetch(`${PROD}/getSlots?code=${theaterCode}_${date}&getDate=false`,
                    {
                        method: "GET",
                        headers: {
                            "sessionId": sessionId  // Fallback value ensures this is always a string
                        }
                    }
                );
                const data = await response.json();
                return { theaterCode, slots: data.slots };
            } catch (error) {
                console.error(`Failed to fetch slots for ${theaterCode}`, error);
                return { theaterCode, slots: [0, 1, 2, 3] }; // Handle errors gracefully
            }
        };

        // Create an array of fetch promises
        const fetchPromises = theaters.map((theaterCode: string) => fetchSlotsForTheater(theaterCode));

        // Wait for all fetches to complete
        Promise.all(fetchPromises).then(results => {
            results.forEach(({ theaterCode, slots }: any) => {
                theaterTimeSlots[theaterCode] = slots;
            });
            dispatch(setTheaterTimeSlots(theaterTimeSlots));
        });
    }

    const fetchLocationInfo = (locationCode: any) => {
        const sessionId = localStorage.getItem("tbt_njkwe_sessionId") || "";
        fetch(`${PROD}/webConfigv2?location=${locationCode}&newWeb=${VERSION}`,
                {
                    method: "GET",
                    headers: {
                        "sessionId": sessionId  // Fallback value ensures this is always a string
                    }
                }
            )
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                dispatch(setTheaterInfo(response));
            })
            .catch((error: any) => {
                console.log(error);
            });
    }
    const handleTheaterProceed = () => {
        const date = new Date(selectedDate);
        navigate(`/private-theatres-in-${selectedViewCity.toLowerCase()}/private-celebration-venue-in-${selectedOption?.value.toLowerCase()}/date-selection?date=${date.getFullYear()}_${date.getMonth() + 1}_${date.getDate()}`);
    };

    return (
        <>
            {showCalendar && (
                <S.CalendarWrapper>
                    <Calendar onChange={(value) => {
                        if (value instanceof Date) {
                            setSelectedDate(value);
                        } else if (Array.isArray(value) && value[0] instanceof Date) {
                            setSelectedDate(value[0]);
                        }
                        setShowCalendar(false);
                        setShowDate(true);
                    }} value={selectedDate} minDate={new Date()} />
                </S.CalendarWrapper>
            )}
            <S.SelectDateCityContainer >
                <S.SelectCityContainer>
                    {citiesData && citiesData.length > 0 &&
                        citiesData.map((city: City, index: number) => (
                            <City
                                key={city.cityName}  // Using cityName as a unique key
                                isSelected={selectedCityIndex === index}  // Check if the current index is selected
                                onClick={() => handleCityClick(index)}  // Pass the index to handleCityClick
                                style={{ flexWrap: "nowrap" }}
                            >
                                {city.cityName}  {/* Display the cityName */}
                            </City>
                        ))
                    }
                </S.SelectCityContainer>
                <S.GreyLine />
                <S.ChooseRow>

                    <Select
                        value={selectedOption}
                        onChange={(option) => setSelectedOption(option as any)} // Set both value and code
                        onMenuOpen={() => setShowCalendar(false)} // Close the calendar when the dropdown is opened
                        options={locationOptions}
                        placeholder={customPlaceholder()}
                        isSearchable={false}
                        components={{
                            DropdownIndicator: () => (
                                <FaAngleDown style={{ color: 'gray' }} />
                            ),
                        }}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "10px",
                                padding: "5px"
                            }),
                            singleValue: (provided) => ({
                                ...provided,
                                fontSize: '12px',
                                color: 'gray',
                                fontFamily: 'DM Sans, sans-serif',
                                zIndex: '1000',
                            }),
                            placeholder: (provided) => ({
                                ...provided,
                                fontSize: '12px',
                                color: 'gray',
                                fontFamily: 'DM Sans, sans-serif',
                            }),
                            menu: (provided) => ({
                                ...provided,
                                fontSize: '12px',
                                fontFamily: 'DM Sans, sans-serif',
                            }),
                            menuPortal: (provided) => ({
                                ...provided,
                                zIndex: 1200, // Ensure it appears above other elements
                            }),
                        }}
                        menuPortalTarget={document.body} // Render the dropdown into the body
                        menuPosition="fixed" // Fix the position for proper display outside container
                        menuPlacement="auto" // Adjust the placement of the dropdown dynamically
                    />
                    <S.RowDate onClick={() => setShowCalendar(!showCalendar)}>
                        <S.SelectLocationDate>
                            <S.SelectDateContainer>
                                <S.DateContainerText>
                                    <S.DateText>
                                        DATE
                                    </S.DateText>
                                    <S.ChooseDateText>
                                        {/* Display selected date if available, otherwise show "Choose a Date" */}
                                        {showDate
                                            ? selectedDate.toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" })
                                            : "Choose a Date"}
                                    </S.ChooseDateText>
                                </S.DateContainerText>
                                <S.CalendarContainer>
                                    <PiCalendar style={{ color: 'gray', top: '25%', fontSize: '24px' }} />

                                </S.CalendarContainer>
                            </S.SelectDateContainer>
                        </S.SelectLocationDate>
                        {window.innerWidth >= 768 && <S.SearchContainer onClick={handleSearchClick}>
                            <FaSearch size={24} style={{ color: 'white' }} />
                        </S.SearchContainer>}
                    </S.RowDate>

                    {window.innerWidth < 768 && <S.SearchSmallScreen onClick={handleSearchClick}>
                        <S.SearchText>Book Now</S.SearchText>
                    </S.SearchSmallScreen>}
                </S.ChooseRow>
                <BookingDone/>
            </S.SelectDateCityContainer>
        </>
    );
};

export default SelectDateCity;
